var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-10", attrs: { align: "center" } },
    [
      _c(
        "b-card",
        {
          staticClass: "p-2",
          style: _vm.isMobile ? "max-width: 360px" : "max-width: 960px",
          attrs: { "no-body": "" },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { size: "sm", variant: "outline-primary" },
                    on: {
                      click: function ($event) {
                        _vm.$emit("update:inputData", _vm.inputData)
                        _vm.$emit("input", 2)
                      },
                    },
                  },
                  [
                    _c("b-icon", { attrs: { icon: "arrow90deg-left" } }),
                    _vm._v(" Ödev Listesine Dön "),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "col-12 text-center" }, [
              _c("h6", [
                _vm._v(
                  "Son Kontrol Tarihi: " +
                    _vm._s(_vm._f("momentDate")(_vm.endDate)) +
                    " Saat: " +
                    _vm._s(_vm._f("moment")(_vm.endDate))
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-12 text-center" }, [
              _c("h4", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(_vm._s(_vm.homework.HomeworkName)),
              ]),
            ]),
            _vm.homework.HomeworkDescription
              ? _c("div", { staticClass: "col-12 text-center" }, [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.homework.HomeworkDescription),
                    },
                  }),
                ])
              : _vm._e(),
          ]),
          _vm.homework.HomeworkAttachments.filter((s) => s.FileKey != null)
            .length > 0
            ? _c("div", { staticClass: "row my-2 mx-2" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("h4", { staticStyle: { color: "blue" } }, [
                      _vm._v("Ödev Dokümanları"),
                    ]),
                    _c("FileAgent", {
                      staticStyle: { "max-width": "480px" },
                      attrs: {
                        maxSize: "100MB",
                        files: _vm.homework.HomeworkAttachments,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.homework.HomeworkAttachments.filter((s) => s.Link != null)
            .length > 0
            ? _c("div", { staticClass: "row my-2 mx-2" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "h4",
                    { staticClass: "mb-2", staticStyle: { color: "red" } },
                    [_vm._v("YouTube Linkleri")]
                  ),
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.links, function (item, idx) {
                      return _c(
                        "div",
                        {
                          key: idx,
                          staticClass:
                            "col d-flex justify-content-center flex-wrap",
                        },
                        [
                          _c("iframe", {
                            staticClass: "mx-1",
                            attrs: {
                              width: "auto",
                              height: "155",
                              src: item,
                              title: "YouTube video player",
                              allow:
                                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;",
                            },
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
          !_vm.isLocked
            ? _c("div", { staticClass: "mt-5" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("v-textarea", {
                        staticClass: "mx-1",
                        staticStyle: { "max-width": "480px" },
                        attrs: {
                          label: "Cevap Yaz",
                          hint: "Metin ekleyerek de ödevi cevaplayabilirsin.",
                          outlined: "",
                          "auto-grow": "",
                          rows: "4",
                        },
                        model: {
                          value: _vm.textAnswer.Comment,
                          callback: function ($$v) {
                            _vm.$set(_vm.textAnswer, "Comment", $$v)
                          },
                          expression: "textAnswer.Comment",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("v-file-input", {
                        staticStyle: { "max-width": "480px" },
                        attrs: {
                          counter: "",
                          label: "Cevap Yükle",
                          multiple: "",
                          placeholder: "Cevap Yükle",
                          "prepend-icon": "",
                          "prepend-inner-icon": "mdi-paperclip",
                          outlined: "",
                          "show-size": 1000,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function ({ index, text }) {
                                return [
                                  index < 3
                                    ? _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            color: "primary",
                                            dark: "",
                                            label: "",
                                            small: "",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(text) + " ")]
                                      )
                                    : index === 2
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-overline grey--text text--darken-3 mx-2",
                                        },
                                        [
                                          _vm._v(
                                            " +" +
                                              _vm._s(_vm.files.length - 2) +
                                              " Dosya "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3076145010
                        ),
                        model: {
                          value: _vm.files,
                          callback: function ($$v) {
                            _vm.files = $$v
                          },
                          expression: "files",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "text-center m-3" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "primary",
                          disabled:
                            _vm.loading ||
                            _vm.isLocked ||
                            (_vm.files.length <= 0 &&
                              _vm.textAnswer.Comment == null),
                        },
                        on: { click: _vm.prepareUpload },
                      },
                      [_vm._v("CEVAPLARI YÜKLE")]
                    ),
                  ],
                  1
                ),
              ])
            : _c("div", [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "b-alert",
                        {
                          staticStyle: { "max-width": "450px" },
                          attrs: { show: "", variant: "warning" },
                        },
                        [
                          _vm._v(
                            "Ödevin teslim süresi dolmuştur, yükleme yapılamaz."
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
          _vm.oldAnswers.length > 0
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("v-divider", { staticClass: "mx-4" }),
                    _c(
                      "h4",
                      { staticClass: "mt-3", staticStyle: { color: "green" } },
                      [_vm._v("Yüklediğim Cevaplar")]
                    ),
                    _c("FileAgent", {
                      staticStyle: { "max-width": "480px" },
                      attrs: { files: _vm.oldAnswers },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }