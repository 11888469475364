<template>
  <v-container fluid>
    <v-data-table
        :headers="headers"
        :items="rows"
        :options.sync="options"
        :server-items-length="totalRows"
        :loading="loading"
        multi-sort
        :sort-by="options.sortBy"
        :sort-desc="options.sortDesc"
        disable-pagination
        hide-default-footer
        mobile-breakpoint="0"
    >
      <template v-slot:[`item.EndDate`]="{ item }">
        {{ item.EndDate | formattedDate }}
      </template>
      <template v-slot:[`item.PlannedDate`]="{ item }">
        {{ item.PlannedDate | formattedDate }}
      </template>
      <template v-slot:[`item.DoneDate`]="{ item }">
        {{ item.DoneDate | formattedDate }}
      </template>
      <template v-slot:[`item.StartDate`]="{ item }">
        {{ item.StartDate | formattedDate }}
      </template>
      <template v-slot:[`item.CorrectCount`]="{ item }">
        {{ item.CorrectCount }} / {{ item.WrongCount }} / {{ item.EmptyCount }}
      </template>
      <template v-slot:[`item.Status`]="{ item }">
        <span v-if="item.Status" style="color: forestgreen">YAPTI</span>
        <span v-else style="color: red">YAPMADI</span>
      </template>
      <template v-slot:[`item.ClassStatus`]="{ item }">
        <v-progress-linear
            rounded
            :value="item.CompletedStudentCount > 0 ? item.CompletedStudentCount / item.StudentCount * 100 : 0"
            :color="getColor(item)"
            height="25"
        >
          <strong>{{ item.CompletedStudentCount }}/{{ item.StudentCount }}</strong>
        </v-progress-linear>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <v-btn v-if="!item.IsLocked" icon small @click.stop="openHomeworkAnswerDialog(item)">
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-alert dense type="info" text class="m-5">Bugüne planladığın ya da kontrol tarihi yarın olan ödevin bulunmuyor.</v-alert>
      </template>
    </v-data-table>
    <v-dialog v-model="homeworkAnswerDialog" max-width="500">
      <v-card flat>
        <v-card-subtitle></v-card-subtitle>
        <v-card-text>
          <odev-cevaplari v-if="homeworkAnswerDialog" :test-id="answerItem.SourceTestId" :hw-id="answerItem.HomeworkId"></odev-cevaplari>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import ApiService from "@/core/services/api.service";
import OdevCevaplari from "@/view/pages/homework/HomeworkAnswers.vue";

export default {
  components: {OdevCevaplari},
  data() {
    return {
      totalRows: 0,
      rows: [],
      loading: true,
      options: {sortBy: ['EndDate'], sortDesc: [false]},
      headers: [
        {text: 'Ders', value: 'LessonName'},
        {text: 'Kaynak', value: 'SourceName'},
        {text: 'Konu', value: 'SubjectName'},
        {text: 'Test', value: 'SourceTestName'},
        {text: 'Sayfa', value: 'PageNumbers'},
        // {text: 'Ödev Verilme Tarihi', value: 'StartDate'},
        {text: 'Son Kontrol Tarihi', value: 'EndDate'},
        {text: 'Planlanan Tarihi', value: 'PlannedDate'},
        // {text: 'Tamamlama Tarihi', value: 'DoneDate'},
        {text: 'Soru Sayısı', value: 'QuestionCount'},
        {text: 'D/Y/B', value: 'CorrectCount', width: 100},
        {text: 'Durum', value: 'Status'},
        {text: 'Sınıf Durumu', value: 'ClassStatus', sortable: false},
        {text: '', value: 'Actions', sortable: false}
      ],
      homeworkAnswerDialog: false,
      answerItem: {
        HomeworkId: 0,
        SourceTestId: 0
      },
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    options: {
      handler() {
        this.getData()
      },
      deep: true
    },
    homeworkAnswerDialog(val) {
      if (!val) {
        this.getData()
        this.answerItem = {
          HomeworkId: 0,
          SourceTestId: 0
        }
      }
    }
  },
  methods: {
    getData() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/Homework/student-homework-planing',
          {
            sortBy: this.options.sortBy,
            descending: this.options.sortDesc,
            page: 1,
            rowsPerPage: -1,
            query: {
              IsCurrent: true
            }
          })
          .then((data) => {
            this.rows = data.data.Results
            this.totalRows = data.data.TotalNumberOfRecords
            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    },

    openHomeworkAnswerDialog(item) {
      this.answerItem = {
        HomeworkId: item.HomeworkId,
        SourceTestId: item.SourceTestId
      }
      this.homeworkAnswerDialog = true;
    },

    showDay(date) {
      return moment(date).locale('tr').format('Do MMMM dddd')
    },

    getColor(item) {
      if (item.CompletedStudentCount == item.StudentCount) return 'green'
      else if (item.CompletedStudentCount > 0) return 'yellow'
      else return 'red'
    }
  }
}
</script>
