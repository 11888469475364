<template>
  <div class="mt-10" align="center">
    <b-card no-body style="max-width: 360px" class="p-2">
      <div class="row">
        <div class="col-12">
          <b-button
            size="sm"
            class=""
            variant="outline-primary"
            @click="
              $emit('update:inputData', inputData)
              $emit('input', 2)
            "
          >
            <b-icon icon="arrow90deg-left"></b-icon> Ödev Listesine Dön
          </b-button>

          <template>
            <v-dialog v-model="dialog" max-width="360px">
              <template v-slot:activator="{ on, attrs }">
                <b-button size="sm" class="ml-2" variant="primary" v-bind="attrs" v-on="on">
                  Kazanımlar
                </b-button>
              </template>
              <v-card>
                <v-card-title class="text-h5"> Kazanımlar </v-card-title>
                <v-card-text
                  ><div v-for="(i, index) in sourceTest.Achievements2" :key="index">
                    {{ i.LessonUnitName }} <br />
                    {{ i.ParentSubjectName }}<br />
                    {{ i.ChildSubjectName }}<br />
                    {{ i.ParentAchievementName }}<br />
                    {{ i.ChildAchievementName }}<br />
                    <v-divider></v-divider></div
                ></v-card-text>
              </v-card>
            </v-dialog>
          </template>
        </div>

        <!-- <div class="col-12 text-center">
          <h6>Son Kontrol Tarihi: {{ achievements }}</h6>
        </div> -->
        <div class="col-12 text-center">
          <h6>Son Kontrol Tarihi: {{ endDate | momentDate }} Saat: {{ endDate | moment }}</h6>
        </div>
        <div class="col-12 text-center">
          <h6>{{ sourceTest.SourceName }}</h6>
          <h6 class="font-weight-normal">
            {{ sourceTest.SourceChapterName }} - {{ sourceTest.Name }}
          </h6>
          <v-spacer></v-spacer>
          <v-alert type="warning" color="orange" v-show="isLocked"
            >Ödev kontrol tarihi geçtiği için öğretmenimiz, soruların cevaplarının işaretlenmesini
            kapatmıştır. Ödevinizi tamamladıysanız, lütfen öğretmenimizle iletişime
            geçiniz.</v-alert
          >
          <h6 class="font-weight-normal">CEVAPLARI</h6>
        </div>
      </div>
      <div v-for="(item, index) in homeworkAnswers" :key="index">
        <div class="mt-3 d-flex justify-content-center">
          <div class="text-center mt-2">{{ item.QuestionNumber.toString() }}) &nbsp;</div>
          <div class="text-center">
            <b-form-radio-group
              v-model="item.TestOptionTypeId"
              size="md"
              name="radio-btn-outline"
              buttons
              @change="saveTestAnswerToList(item)"
            >
              <b-form-radio
                v-for="option in testOptionTypes"
                :key="option.Id"
                :value="option.Id"
                :name="option.Name"
                :disabled="option.Id != item.TestOptionTypeId ? option.disabled : false"
                :button-variant="item.color ? item.color : 'outline-primary'"
              >
                {{ option.Name }}
              </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="text-center m-3">
        <b-progress
          v-if="correctCount === 0 && wrongCount === 0"
          :max="progressMax"
          variant="primary"
          show-progress
          class="mt-2"
        >
          <b-progress-bar
            :value="answeredQuestionCount"
            :label="`${((answeredQuestionCount / progressMax) * 100).toFixed(0)}%`"
          ></b-progress-bar>
        </b-progress>
        <b-progress
          v-else
          v-b-popover.hover="correctCount + ' D / ' + wrongCount + ' Y / ' + emptyCount + ' B'"
          class="mt-3"
          :max="progressMax"
          show-value
        >
          <b-progress-bar :value="correctCount" variant="success"></b-progress-bar>
          <b-progress-bar :value="wrongCount" variant="danger"></b-progress-bar>
          <b-progress-bar :value="emptyCount" variant="secondary"></b-progress-bar>
        </b-progress>
      </div>
      <div class="text-center m-3" v-if="!isLocked">
        <b-button variant="primary" class="mt-3" @click="saveTestAnswersBulk">KAYDET</b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service.js'
import Swal from 'sweetalert2'
import moment from 'moment'

export default {
  props: ['sourceTestId', 'homeworkId', 'screenLevel', 'inputData', 'isLocked', 'endDate'],
  name: 'odev-cevaplari',
  data() {
    return {
      answerCount: 0,
      testOptionTypes: [],
      homeworkAnswers: [],
      sourceTest: {},
      answersBulk: [],
      sourceTestAnswers: [],
      sourceTestResult: {}
    }
  },
  computed: {
    progressMax() {
      return this.homeworkAnswers.length
    },
    correctCount() {
      return this.homeworkAnswers.filter((s) => s.color == 'outline-success').length
    },
    wrongCount() {
      return this.homeworkAnswers.filter((s) => s.color == 'outline-danger').length
    },
    emptyCount() {
      return this.homeworkAnswers.filter((s) => s.color == null || s.color.length == 0).length
    },
    answeredQuestionCount() {
      return this.homeworkAnswers.filter((s) => s.TestOptionTypeId != null).length
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('LT') : ''
    },
    momentDate: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM') : ''
    }
  },
  watch: {
    sourceTestResult(newValue) {
      if (newValue != null) {
        Object.assign(this.$parent.$parent.sourceTestResult, newValue)
      }
    }
  },
  created() {
    this.getTestOptionTypesFromApi()
  },
  mounted() {
    this.getAnswers()
    this.getSourceTestAnswersFromApi()
  },
  methods: {
    getTestOptionTypesFromApi() {
      ApiService.setHeader()
      ApiService.post('api/TestOptionType', {
        sortBy: ['DisplayOrder'],
        descending: [false],
        rowsPerPage: -1,
        page: 1
      })
        .then((data) => {
          this.testOptionTypes = data.data.Results
          this.testOptionTypes.push({
            DisplayOrder: 99,
            Name: 'Boş',
            Id: 0
          })

          if (this.isLocked) {
            for (let index = 0; index < this.testOptionTypes.length; index++) {
              const element = this.testOptionTypes[index]
              element.disabled = true
            }
          }
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },

    async getSourceTestAnswersFromApi() {
      ApiService.setHeader()
      try {
        let data = await ApiService.get('api/SourceTest/' + this.sourceTestId)
        this.sourceTest = data.data
        this.answerCount = data.data.QuestionCount
      } catch (response) {
        ApiService.showError(response.response)
      }
      try {
        ApiService.setHeader()
        let data2 = await ApiService.post('api/HomeworkAnswer', {
          sortBy: ['QuestionNumber'],
          descending: [false],
          rowsPerPage: -1,
          page: 1,
          query: {
            SourceTestId: this.sourceTestId,
            HomeworkId: this.homeworkId
          }
        })

        this.homeworkAnswers = data2.data.Results
        for (let index = 1; index <= this.answerCount; index++) {
          if (this.homeworkAnswers.filter((s) => s.QuestionNumber == index).length == 0) {
            var element = {
              QuestionNumber: index,
              HomeworkId: this.homeworkId,
              Id: 0,
              SourceTestId: this.sourceTestId
            }
            this.homeworkAnswers.push(element)
            this.homeworkAnswers = this.homeworkAnswers.sort(function (a, b) {
              return a.QuestionNumber - b.QuestionNumber
            })
          } else if (this.sourceTestAnswers.length > 0) {
            var hasSourceTestAnswer =
              this.sourceTestAnswers.filter(
                (s) =>
                  s.QuestionNumber == this.homeworkAnswers[index - 1].QuestionNumber &&
                  s.TestOptionTypeIds.length > 0
              ).length == 1

            var isCorrectAnswer =
              this.sourceTestAnswers.filter(
                (s) =>
                  s.QuestionNumber == this.homeworkAnswers[index - 1].QuestionNumber &&
                  s.TestOptionTypeIds.includes(this.homeworkAnswers[index - 1].TestOptionTypeId)
              ).length == 1

            if (hasSourceTestAnswer) {
              if (isCorrectAnswer) {
                this.homeworkAnswers.filter(
                  (s) => s.QuestionNumber == this.homeworkAnswers[index - 1].QuestionNumber
                )[0].color = 'outline-success'
              } else {
                this.homeworkAnswers.filter(
                  (s) => s.QuestionNumber == this.homeworkAnswers[index - 1].QuestionNumber
                )[0].color = 'outline-danger'
              }
            } else {
              this.homeworkAnswers.filter(
                (s) => s.QuestionNumber == this.homeworkAnswers[index - 1].QuestionNumber
              )[0].color = 'outline-secondary'
            }
          }
        }
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
    },

    deleteItem(item) {
      ApiService.setHeader()
      ApiService.delete('api/HomeworkAnswer/' + item.Id)
        .then(() => {
          item.TestOptionTypeId = null
          Swal.fire({
            toast: true,
            position: 'bottom',
            icon: 'success',
            title: 'Değişklikler Uygulandı',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .catch(({ response }) => {
          this.snackbarColor = 'red'
          this.snackbarText = response.data.SummaryMessage
          this.snackbar = true
        })
    },

    saveTestAnswerToList(item) {
      for (var i = 0; i < this.answersBulk.length; i++) {
        if (this.answersBulk[i].QuestionNumber == item.QuestionNumber) {
          this.answersBulk.splice(i, 1)
          break
        }
      }
      this.answersBulk.push(item)
    },

    saveTestAnswersBulk() {
      this.calcResults()
   
      this.$emit('inputData', this.homeworkAnswers.filter((a) => a.TestOptionTypeId).length)
      ApiService.setHeader()
      ApiService.put('api/HomeworkAnswer/upsert-bulk', {
        Answers: this.answersBulk,
        HomeworkId: this.homeworkId,
        SourceTestId: this.sourceTestId
      })
        .then(() => {
          this.getSourceTestAnswersFromApi()

          Swal.fire({
            toast: true,
            position: 'bottom',
            icon: 'success',
            title: 'Değişklikler Uygulandı',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
      this.answersBulk = []
    },

    getAnswers() {
      ApiService.setHeader()
      ApiService.post('api/SourceTestAnswer', {
        sortBy: ['QuestionNumber'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: { SourceTestId: this.sourceTestId }
      })
        .then((data) => {
          this.sourceTestAnswers = data.data.Results
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },
    calcResults() {
      if (this.answersBulk.length > 0) {
        var model = { AnswerList: [] }

        model.AnswerList = this.homeworkAnswers.map(function (s) {
          return {
            Id: s.Id,
            HomeworkId: s.HomeworkId,
            SourceTestId: s.SourceTestId,
            QuestionNumber: s.QuestionNumber,
            TestOptionTypeId: s.TestOptionTypeId
          }
        })

        this.answersBulk = this.answersBulk.map(function (s) {
          return {
            Id: s.Id,
            HomeworkId: s.HomeworkId,
            SourceTestId: s.SourceTestId,
            QuestionNumber: s.QuestionNumber,
            TestOptionTypeId: s.TestOptionTypeId
          }
        })

        for (var i = 0, l = model.AnswerList.length; i < l; i++) {
          for (var j = 0, ll = this.answersBulk.length; j < ll; j++) {
            if (model.AnswerList[i].QuestionNumber === this.answersBulk[j].QuestionNumber) {
              model.AnswerList.splice(i, 1, this.answersBulk[j])
              break
            }
          }
        }
        ApiService.setHeader()
        ApiService.post('api/HomeworkAnswer/calculateresult', model)
          .then((data) => (this.sourceTestResult = data.data))
          .catch(({ response }) => {
            ApiService.showError(response)
          })
      }
    }
  }
}
</script>
