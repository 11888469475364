<template>
  <v-card flat id="first">
    <v-card-title class="px-1 mx-md-3 pb-3">
      <b-btn
          size="sm"
          :variant="!isCompleted && !isNotCompleted && isCurrent ? 'primary' : ''"
          class="mx-0 px-1 p-sm-2 mt-1 odevlerim"
          @click="
          isCompleted = false
          isNotCompleted = false
          isCurrent = true
          getHomeworksFromApi()
          screenLevel = 1
        "
      >Bugün Yapacaklarım
      </b-btn>
      <b-btn
          size="sm"
          :variant="!isCompleted && !isNotCompleted && !isCurrent ? 'primary' : ''"
          class="ml-1 ml-sm-2 px-1 p-sm-2 mt-1 odevlerim"
          @click="
          isCompleted = false
          isNotCompleted = false
          isCurrent = false
          getHomeworksFromApi()
          screenLevel = 1
        "
      >Ödevlerim
      </b-btn>
      <b-btn
          size="sm"
          class="mx-1 mx-sm-2 px-1 p-sm-2 yaptiklarim mt-1"
          :variant="isCompleted && !isNotCompleted ? 'primary' : ''"
          @click="
          isCompleted = true
          isNotCompleted = false
          isCurrent = false
          getHomeworksFromApi()
          screenLevel = 1
        "
      >Yaptıklarım
      </b-btn>
      <b-btn
          size="sm"
          class="mx-0 px-1 p-sm-2 yapmadiklarim mt-1"
          :variant="!isCompleted && isNotCompleted ? 'primary' : ''"
          @click="
          isCompleted = false
          isNotCompleted = true
          isCurrent = false
          getHomeworksFromApi()
          screenLevel = 1
        "
      >Yapmadıklarım
      </b-btn>
      <v-spacer></v-spacer>
      <v-tooltip v-if="isCurrent && tomorrowSourceTests.length > 0 && !$vuetify.breakpoint.mobile" left color="success">
        <template v-slot:activator="{ on }">
          <v-chip small label class="mr-2" color="primary" v-on="on">Yarın Kontrol Edilecek Ödevler</v-chip>
        </template>
        <span class=" justify-center d-flex font-weight-boldest"></span>
        <ul class="mt-1">
          <li v-for="(item, index) in tomorrowSourceTests" :key="index">
            {{ item.SourceName }}
            <ul>
              <li v-for="(testName) in item.SourceTestNames" :key="testName">
                {{ testName }}
              </li>
            </ul>
          </li>
        </ul>
      </v-tooltip>
      <v-btn
          class="tarihegore"
          :to="{ name: 'odevler' }"
          icon
          small
          v-show="!$vuetify.breakpoint.mobile"
          v-b-tooltip.hover.top="'Ödevleri Tarihe Göre Görüntüle'"
      >
        <v-icon small> fas fa-sort-amount-down</v-icon>
      </v-btn>
      <v-dialog
          ref="dialog"
          v-model="dateDialog"
          :return-value.sync="/* eslint-disable vue/no-mutating-props */ dates"
          persistent
          width="290px"
      >
        <v-card>
          <v-date-picker
              v-model="/* eslint-disable vue/no-mutating-props */ dates"
              scrollable
              range
              color="green lighten-1"
              header-color="primary"
              locale="tr"
              min="2021-09-13"
              first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateDialog = false"> İPTAL</v-btn>
            <v-btn
                text
                color="primary"
                @click="
                getHomeworksFromApi()
                $refs.dialog.save(dates)
              "
            >
              ARA
            </v-btn>
          </v-date-picker>
          <v-radio-group class="mx-3" v-model="orderByCreatedDate">
            <v-radio label="Son Kontrol Tarihine Göre" :value="false"></v-radio>
            <v-radio label="Ödev Yapma Tarihine Göre" :value="true"></v-radio>
          </v-radio-group>
        </v-card>
      </v-dialog>
      <!--          <v-btn-->
      <!--            size="sm"-->
      <!--            fab-->
      <!--            small-->
      <!--            color="grey"-->
      <!--            fixed-->
      <!--            right-->
      <!--            outlined-->
      <!--            bottom-->
      <!--            @click="startTour()"-->
      <!--            v-if="homeworksByLesson.length > 0 && screenLevel === 1"-->
      <!--          >-->
      <!--            <v-icon class="fas fa-question"></v-icon>-->
      <!--          </v-btn>-->
      <!--          <v-btn-->
      <!--            size="sm"-->
      <!--            fab-->
      <!--            small-->
      <!--            color="grey"-->
      <!--            fixed-->
      <!--            right-->
      <!--            outlined-->
      <!--            bottom-->
      <!--            @click="startTour2()"-->
      <!--            v-else-if="homeworksByLesson.length === 0 && screenLevel === 1"-->
      <!--          >-->
      <!--            <v-icon class="fas fa-question"></v-icon>-->
      <!--          </v-btn>-->
    </v-card-title>
    <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        color="primary"
        class="mb-0"
    ></v-progress-linear>

    <v-card-text>
      <div v-if="$route.name == 'odevlerim' && (screenLevel == 1 || screenLevel == 2)">
        <div class="d-flex justify-content-around w-100 mb-2">
          <v-btn small icon @click="previousWeek">
            <v-icon left> mdi-chevron-left</v-icon>
            ÖNCEKİ
          </v-btn>
          <v-toolbar-title>
            <div class="d-flex justify-center align-center">
              {{ dates[0] | momentDate }} - {{ dates[1] | momentDate }}
              <v-btn class="float-right ml-1 takvim" icon small @click="dateDialog = true">
                <v-icon> mdi-calendar</v-icon>
              </v-btn>
            </div>
          </v-toolbar-title>
          <v-btn small icon @click="nextWeek">
            SONRAKİ
            <v-icon right> mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <template v-if="!$vuetify.breakpoint.mobile && !isCompleted && !isNotCompleted && isCurrent">
        <div>
          <homework-planing-widget></homework-planing-widget>
        </div>
      </template>
      <template v-else>
        <div v-if="screenLevel == 1">
          <div class="table-responsive d-none d-sm-block">
            <table class="table table-hover table-vertical-center mt-2">
              <thead>
              <tr class="text-center">
                <th class="text-start" scope="col">Ders</th>
                <th scope="col">
                  Test <br/>
                  Sayısı
                </th>
                <th scope="col">
                  İşaretleme Yapılmış <br/>
                  Test Sayısı
                </th>
                <th scope="col">
                  İşaretleme Yapılmamış <br/>
                  Test Sayısı
                </th>
                <th scope="col">
                  Soru <br/>
                  Sayısı
                </th>
                <th scope="col">
                  İşaretleme Yapılmış <br/>
                  Soru Sayısı
                </th>
                <th scope="col">
                  İşaretleme Yapılmamış <br/>
                  Soru Sayısı
                </th>
                <th scope="col">
                  Soru <br/>
                  Çözüm Yüzdesi
                </th>
                <th scope="col">
                  Ödevin <br/>
                  Ayrıntısı
                </th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center" v-if="loading">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </tr>
              <template v-else v-for="(element, index) in homeworksByLesson">
                <tr class="text-center" v-bind:key="index">
                  <td class="text-start mt-1" style="max-width: 130px">
                    <span>{{ element.key }} </span>
                  </td>
                  <td>
                    <span>{{ element.unAnsweredTestCount + element.answeredTestCount }} </span>
                  </td>
                  <td>
                    <span>{{ element.answeredTestCount }} </span>
                  </td>
                  <td>
                    <span>{{ element.unAnsweredTestCount }} </span>
                  </td>
                  <td>
                    <span>{{
                        element.unAnsweredQuestionCount + element.answeredQuestionCount
                      }}</span>
                  </td>
                  <td>
                    <span>{{ element.answeredQuestionCount }}</span>
                  </td>
                  <td>
                    <span>{{ element.unAnsweredQuestionCount }}</span>
                  </td>
                  <td>
                    <b-progress v-if="element.scy != null" max="100" variant="primary">
                      <b-progress-bar
                          :value="element.scy"
                          :label="`${element.scy}%`"
                      ></b-progress-bar>
                    </b-progress>
                    <span>{{
                        element.correctCount +
                        ' D / ' +
                        element.wrongCount +
                        ' Y / ' +
                        element.emptyCount +
                        ' B'
                      }}</span>
                  </td>
                  <td>
                    <b-button
                        size="sm"
                        variant="outline-primary"
                        v-b-tooltip.hover.left="'Ödevin Ayrıntısı'"
                        :id="'buttonLesson' + index"
                        @click="
                        screenLevel = 2
                        screenLevel2Key = index
                      "
                    >
                      <i class="ki ki-long-arrow-next icon-nm m-0 p-0"></i>
                    </b-button>
                  </td>
                </tr>
              </template>
              <tr class="text-center">
                <td class="text-start">TOPLAM</td>
                <td>
                  {{
                    homeworkByLessonSum.answeredTestCount + homeworkByLessonSum.unAnsweredTestCount
                  }}
                </td>
                <td>
                  {{ homeworkByLessonSum.answeredTestCount }}
                </td>
                <td>
                  {{ homeworkByLessonSum.unAnsweredTestCount }}
                </td>
                <td>
                  {{
                    homeworkByLessonSum.unAnsweredQuestionCount +
                    homeworkByLessonSum.answeredQuestionCount
                  }}
                </td>
                <td>{{ homeworkByLessonSum.answeredQuestionCount }}</td>
                <td>
                  {{ homeworkByLessonSum.unAnsweredQuestionCount }}
                </td>
                <td>
                  <b-progress v-if="homeworkByLessonSum.scy != null" max="100" variant="primary">
                    <b-progress-bar
                        :value="homeworkByLessonSum.scy"
                        :label="`${homeworkByLessonSum.scy}%`"
                    ></b-progress-bar>
                  </b-progress>
                  <span>{{
                      homeworkByLessonSum.correctCount +
                      ' D / ' +
                      homeworkByLessonSum.wrongCount +
                      ' Y / ' +
                      homeworkByLessonSum.emptyCount +
                      ' B'
                    }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div
              v-for="(element, index) in homeworksByLesson"
              :key="element.key"
              class="d-flex mt-1 d-sm-none align-center"
          >
            <div class="text-left solsutun">
              <span class="text-dark-75 font-weight-bolder font-size-lg">
                {{ element.key }}
              </span>
            </div>
            <div class="text-left flex-grow-1 testsoru ml-2">
            <span class="font-weight-bolder"
            ><b-badge
                v-if="element.unAnsweredQuestionCount > 0"
                pill
                :class="'tooltip-target-test-' + index"
                light
                :style="
                  'color: #fff;background-color:' +
                  getColorCode(element.answeredTestCount, element.unAnsweredTestCount)
                "
                :id="'tooltip-target-test-' + index"
            >{{ element.answeredTestCount + element.unAnsweredTestCount }} Test</b-badge
            >
              <b-tooltip
                  v-if="element.unAnsweredQuestionCount > 0"
                  :target="'tooltip-target-test-' + index"
                  triggers="hover"
              >
                İşaretleme yapılmış test sayısı: {{ element.answeredTestCount }} <br/>
                İşaretleme yapılmamış test sayısı: {{ element.unAnsweredTestCount }} <br/>
                Oran: %{{
                  Math.round(
                      (element.answeredTestCount /
                          (element.answeredTestCount + element.unAnsweredTestCount)) *
                      100
                  )
                }}
              </b-tooltip>
              <b-badge
                  v-if="element.unAnsweredQuestionCount > 0"
                  pill
                  class="mx-1"
                  :class="'tooltip-target-question-' + index"
                  :style="
                  'color: #fff;background-color:' +
                  getColorCode(element.answeredQuestionCount, element.unAnsweredQuestionCount)
                "
                  :id="'tooltip-target-question-' + index"
              >{{ element.unAnsweredQuestionCount + element.answeredQuestionCount }} Soru</b-badge
              >
              <b-tooltip
                  v-if="element.unAnsweredQuestionCount > 0"
                  :target="'tooltip-target-question-' + index"
                  triggers="hover"
              >
                İşaretlenmiş soru sayısı: {{ element.answeredQuestionCount }} <br/>
                İşaretlenmemiş soru sayısı: {{ element.unAnsweredQuestionCount }} <br/>
                Oran: %{{
                  Math.round(
                      (element.answeredQuestionCount /
                          (element.answeredQuestionCount + element.unAnsweredQuestionCount)) *
                      100
                  )
                }}
              </b-tooltip>
            </span>
            </div>
            <div class="text-right ayrinti mt-2">
              <b-button
                  size="sm"
                  class=""
                  variant="outline-primary"
                  v-b-tooltip.hover.left="'Ödevin Ayrıntısı'"
                  :id="'buttonLesson' + index"
                  @click="
                screenLevel = 2
                screenLevel2Key = index
              "
              >
                <i class="ki ki-long-arrow-next icon-nm m-0 p-0"></i>
              </b-button>
            </div>
          </div>

          <div class="d-flex mt-5 d-sm-none" v-if="homeworksByLesson.length > 0">
            <div class="text-left mt-1">
              <span class="text-dark font-weight-bolder mr-1 font-size-lg">Toplam</span>
            </div>
            <div class="text-left flex-grow-1">
              <b-badge
                  pill
                  light
                  class="m-1"
                  :style="
                'color: #fff;background-color:' +
                getColorCode(
                  homeworkByLessonSum.answeredTestCount,
                  homeworkByLessonSum.unAnsweredTestCount
                )
              "
                  id="tooltip-target-test-sum"
              >{{ homeworkByLessonSum.answeredTestCount + homeworkByLessonSum.unAnsweredTestCount }}
                Test
              </b-badge>
              <b-tooltip target="tooltip-target-test-sum" triggers="hover">
                İşaretleme yapılmış test sayısı: {{ homeworkByLessonSum.answeredTestCount }}
                <br/>
                İşaretleme yapılmamış test sayısı:
                {{ homeworkByLessonSum.unAnsweredTestCount }} <br/>
                Oran: %{{
                  Math.round(
                      (homeworkByLessonSum.answeredTestCount /
                          (homeworkByLessonSum.answeredTestCount +
                              homeworkByLessonSum.unAnsweredTestCount)) *
                      100
                  )
                }}
              </b-tooltip>
              <b-badge
                  pill
                  class="mx-1"
                  :style="
                'color: #fff;background-color:' +
                getColorCode(
                  homeworkByLessonSum.answeredQuestionCount,
                  homeworkByLessonSum.unAnsweredQuestionCount
                )
              "
                  id="tooltip-target-question-sum"
              >{{
                  homeworkByLessonSum.unAnsweredQuestionCount +
                  homeworkByLessonSum.answeredQuestionCount
                }}
                Soru
              </b-badge>
              <b-tooltip target="tooltip-target-question-sum" triggers="hover">
                İşaretlenmiş soru sayısı: {{ homeworkByLessonSum.answeredQuestionCount }}
                <br/>
                İşaretlenmemiş soru sayısı: {{ homeworkByLessonSum.unAnsweredQuestionCount }}
                <br/>
                Oran: %{{
                  Math.round(
                      (homeworkByLessonSum.answeredQuestionCount /
                          (homeworkByLessonSum.answeredQuestionCount +
                              homeworkByLessonSum.unAnsweredQuestionCount)) *
                      100
                  )
                }}
              </b-tooltip>
            </div>
          </div>
          <div class="d-flex mt-2 d-sm-none" v-if="homeworksByLesson.length > 0">
            <v-spacer></v-spacer>
            <div class="text-right">
              <v-tooltip v-if="isCurrent && tomorrowSourceTests.length > 0" left color="success">
                <template v-slot:activator="{ on }">
                  <v-chip small label color="primary" v-on="on">Yarın Kontrol Edilecek Ödevler</v-chip>
                </template>
                <ul class="mt-1">
                  <li v-for="(item, index) in tomorrowSourceTests" :key="index">
                    {{ item.SourceName }}
                    <ul>
                      <li v-for="(testName) in item.SourceTestNames" :key="testName">
                        {{ testName }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div v-if="screenLevel == 2">
          <div class="row my-2 mx-sm-2">
            <b-button
                size="sm"
                class="mx-3 mt-0 geri"
                id="geri"
                variant="outline-primary"
                @click="
              screenLevel = 1
              screenLevel2Key = null
            "
            >
              <b-icon icon="arrow90deg-left"></b-icon>
            </b-button>
            <h3 class="mt-1">{{ homeworksByLesson[screenLevel2Key].key }}</h3>
          </div>
          <div
              class="row mt-4 m-sm-5"
              :class="
            homeworksByLesson[screenLevel2Key].values.length > 1
              ? 'border border-primary rounded'
              : ''
          "
              v-for="(item, i) in homeworksByLesson[screenLevel2Key].values"
              :key="item.key"
          >
            <div class="text-left col-10">
              <h6>
                <b-icon icon="book" aria-hidden="true"></b-icon>
                {{ item.key }}
              </h6>

              <b-badge
                  v-if="item.key != 'Doküman'"
                  pill
                  light
                  :style="
                'color: #fff;background-color:' +
                getColorCode(item.answeredTestCount, item.unAnsweredTestCount)
              "
                  :id="'tooltip-target-test-source-' + i"
              >{{ item.answeredTestCount + item.unAnsweredTestCount }} Test
              </b-badge>
              <b-tooltip :target="'tooltip-target-test-source-' + i" triggers="hover">
                İşaretleme yapılmış test sayısı: {{ item.answeredTestCount }} <br/>
                İşaretleme yapılmamış test sayısı: {{ item.unAnsweredTestCount }} <br/>
                Oran: %{{
                  Math.round(
                      (item.answeredTestCount / (item.answeredTestCount + item.unAnsweredTestCount)) *
                      100
                  )
                }}
              </b-tooltip>
              <b-badge
                  v-if="item.key != 'Doküman'"
                  pill
                  class="mx-1"
                  :style="
                'color: #fff;background-color:' +
                getColorCode(item.answeredQuestionCount, item.unAnsweredQuestionCount)
              "
                  :id="'tooltip-target-question-source-' + i"
              >{{ item.unAnsweredQuestionCount + item.answeredQuestionCount }} Soru
              </b-badge>
              <b-tooltip
                  v-if="item.key != 'Doküman'"
                  :target="'tooltip-target-question-source-' + i"
                  triggers="hover"
              >
                İşaretlenmiş soru sayısı: {{ item.answeredQuestionCount }} <br/>
                İşaretlenmemiş soru sayısı: {{ item.unAnsweredQuestionCount }} <br/>
                Oran: %{{
                  Math.round(
                      (item.answeredQuestionCount /
                          (item.answeredQuestionCount + item.unAnsweredQuestionCount)) *
                      100
                  )
                }}
              </b-tooltip>
            </div>
            <div class="col-2 text-right">
              <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="
                innercollapseOpenedKey == screenLevel2Key + '-' + i
                  ? (innercollapseOpenedKey = null)
                  : (innercollapseOpenedKey = screenLevel2Key + '-' + i)
              "
                  v-b-toggle="'innercollapse-' + screenLevel2Key + '-' + i"
              >
                <i
                    class="ki icon-nm m-0 p-0"
                    :class="
                  innercollapseOpenedKey == screenLevel2Key + '-' + i
                    ? 'ki-arrow-up'
                    : 'ki-arrow-down'
                "
                ></i>
              </b-button>
            </div>

            <div class="col-12 m-0 p-0">
              <b-collapse visible :id="'innercollapse-' + screenLevel2Key + '-' + i" class="row m-1">
                <div
                    v-for="(thing, idx) in item.values"
                    :key="idx"
                    class="col-sm-6 col-md-4"
                    :class="'test-' + idx"
                >
                  <div v-if="thing.SourceTestId > 0" class="border rounded p-4">
                    <a
                        :id="'atest-' + idx"
                        class="text-gray-800 text-hover-primary fw-bolder fs-6"
                        @click="
                      !thing.SourceTestIsActivity
                        ? goToTestAnswer(thing.HomeworkId, thing.SourceTestId)
                        : ''
                    "
                    >
                      <b-icon icon="pencil" aria-hidden="true"></b-icon>
                      {{ thing.SourceChapterName }} - {{ thing.SourceTestName }} <br/>
                    </a>
                    <b-badge pill class="mt-1"
                    >Sayfa:
                      {{
                        thing.StartPage == thing.EndPage
                            ? thing.StartPage
                            : thing.StartPage + '-' + thing.EndPage
                      }}
                    </b-badge>

                    <b-badge
                        pill
                        variant="danger"
                        class="mt-1 mx-1"
                        :style="
                      'color: #fff;background-color:' +
                      getColorCode(
                        thing.AnsweredQuestionCount,
                        thing.QuestionCount - thing.AnsweredQuestionCount
                      )
                    "
                    >{{ thing.QuestionCount }} Soru
                    </b-badge>

                    <b-badge pill class="mt-1 mx-1"
                    >{{ thing.StartDate | momentDate }} - {{ thing.EndDate | momentDate }}
                    </b-badge>

                    <b-button
                        v-if="thing.SourceTestId > 0"
                        class="label label-inline"
                        :id="'tooltip-target-achievement2-' + thing.HomeworkId + thing.SourceTestId"
                    >
                      {{ thing.Achievements.length }} Kazanım
                    </b-button>
                    <b-popover
                        :target="'tooltip-target-achievement2-' + thing.HomeworkId + thing.SourceTestId"
                        triggers="focus"
                        placement="right"
                    >
                      <template #title>Kazanımlar</template>
                      <div v-for="(i, index) in thing.Achievements" :key="index">
                        {{ i.ChildAchievementName }}<br/>
                        <v-divider inset v-show="index != thing.Achievements.length -1"></v-divider>
                      </div>
                    </b-popover>
                    <!-- etkinlik ödevi yaptım-yapmadım butonu -->
                    <template v-if="thing.SourceTestIsActivity">
                      <br/>
                      <div class="text-right text-white mt-2">
                        <b-button
                            @click="iActuallyDidThisHomework(thing)"
                            :variant="thing.IsHomeworkStudentAnswerDone ? 'success' : 'outline-danger'"
                        >
                          <!-- loading -->
                          <template v-if="loadingAnswer">
                            <b-spinner label="Lütfen bekleyiniz"></b-spinner>
                          </template>
                          <template v-else>
                            <!-- non-loading -->
                            <b-form-checkbox
                                v-model="thing.IsHomeworkStudentAnswerDone"
                                @input="delayedSave"
                                switch
                                size="lg"
                                :variant="thing.IsHomeworkStudentAnswerDone ? 'success' : 'danger'"
                            >
                              {{ thing.IsHomeworkStudentAnswerDone ? 'Yaptım' : 'Yapmadım' }}
                            </b-form-checkbox>
                          </template>
                        </b-button>

                        <!-- switch -->
                        <!-- <template v-if="thing.IsHomeworkStudentAnswerDone"> Yaptım</template>
                            <template v-else> Yapmadım</template>
                            <b-form-checkbox
                              v-model="thing.IsHomeworkStudentAnswerDone"
                              @input="delayedSave"
                              switch
                              size="lg"
                            >
                            </b-form-checkbox> -->
                      </div>
                    </template>
                    <b-progress
                        v-if="!thing.HasAnswer"
                        :max="thing.QuestionCount"
                        variant="primary"
                        show-progress
                        class="mt-2"
                    >
                      <b-progress-bar
                          :value="thing.AnsweredQuestionCount"
                          :label="`${thing.progressValue.toFixed(0)}%`"
                      ></b-progress-bar>
                    </b-progress>
                    <b-progress
                        v-else-if="
                      !thing.SourceTestIsActivity &&
                      thing.SourceTestResult &&
                      thing.SourceTestResult.CorrectCount == 0 &&
                      thing.SourceTestResult.WrongCount == 0
                    "
                        :max="thing.QuestionCount"
                        variant="primary"
                        show-progress
                        class="mt-2"
                    >
                      <b-progress-bar
                          :value="thing.AnsweredQuestionCount"
                          :label="`${thing.progressValue.toFixed(0)}%`"
                      ></b-progress-bar>
                    </b-progress>
                    <b-progress
                        v-else-if="!thing.SourceTestIsActivity && thing.SourceTestResult"
                        v-b-popover.hover="
                      thing.SourceTestResult.CorrectCount +
                      ' D / ' +
                      thing.SourceTestResult.WrongCount +
                      ' Y / ' +
                      thing.SourceTestResult.EmptyCount +
                      ' B'
                    "
                        class="mt-3"
                        :max="
                      thing.SourceTestResult.CorrectCount +
                      thing.SourceTestResult.WrongCount +
                      thing.SourceTestResult.EmptyCount
                    "
                        show-value
                    >
                      <b-progress-bar
                          :value="thing.SourceTestResult.CorrectCount"
                          variant="success"
                      ></b-progress-bar>
                      <b-progress-bar
                          :value="thing.SourceTestResult.WrongCount"
                          variant="danger"
                      ></b-progress-bar>
                      <b-progress-bar
                          :value="thing.SourceTestResult.EmptyCount"
                          variant="secondary"
                      ></b-progress-bar>
                    </b-progress>
                  </div>

                  <div v-else class="border rounded p-4">
                    <a
                        :id="'adocument-' + idx"
                        class="text-gray-800 text-hover-primary fw-bolder fs-6"
                        @click="goToDocumentAnswer(thing.HomeworkId)"
                    >
                      <b-icon icon="file-earmark-check" aria-hidden="true"></b-icon>
                      {{ thing.HomeworkName }} <br/>
                    </a>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
        <div v-if="screenLevel == 3">
          <div v-if="screenLevel3SourceTestId > 0">
            <HomeworkAnswersWidget
                :sourceTestId="screenLevel3SourceTestId"
                :homeworkId="screenLevel3HomeworkId"
                :inputData.sync="editedItem.AnsweredQuestionCount"
                v-model="screenLevel"
                :isLocked="editedItem.IsLocked"
                :endDate="editedItem.EndDate"
            />
          </div>
        </div>
        <div v-if="homeworksByLesson.length == 0 && isCurrent" class="text-center">
          <v-chip large label color="primary" outlined :to="{name: 'odev-planlama'}" style="font-size: 12px">
            Bugüne planladığın ödevin bulunmuyor.<br> Planlama yapmak için tıkla.
          </v-chip>
        </div>
        <div v-else-if="homeworksByLesson.length == 0" class="text-center">
          {{ dates[0] | moment }} - <span v-if="dates[1]"> {{ dates[1] | moment }}</span>
          <span v-else>∞</span>
          Tarihlerinde ödeviniz bulunmamaktadır.
          <p>Üstte bulunan takvim ikonuna tıklayarak tarih filtresini kullanabilirsiniz.</p>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
/* eslint-disable vue/no-mutating-props */

import ApiService from '@/core/services/api.service.js'
import moment from 'moment'
import HomeworkAnswersWidget from '@/view/pages/dashboard_widgets/HomeworkAnswersWidget.vue'
import Vue from 'vue'
import VueShepherd from 'vue-shepherd'
import HomeworkPlaningWidget from "@/view/pages/dashboard_widgets/HomeworkPlaningWidget.vue";

Vue.use(VueShepherd)

export default {
  props: ['dates'],
  data() {
    return {
      loading: false,
      loadingAnswer: false,
      homeworks: [],
      isCompleted: false,
      dateDialog: false,
      tab: null,
      homeworksByLesson: [],
      isCurrent: moment().isSameOrAfter(this.dates[0]) && moment().isSameOrBefore(this.dates[1]),
      isNotCompleted: false,
      screenLevel: 1,
      screenLevel2Key: null,
      innercollapseOpenedKey: null,
      screenLevel3SourceTestId: null,
      screenLevel3HomeworkId: null,
      scrollOptions: {duration: 500, offset: 0, easing: 'easeInOutCubic'},
      homeworkByLessonSum: {},
      editedItem: {},
      sourceTestResult: {},
      orderByCreatedDate: false,
      tomorrowSourceTests: [],
      tourSteps: [
        {
          id: 'step-1',
          title: 'Yardım Sihirbazı',
          attachTo: {
            element: '.takvim',
            on: 'bottom'
          },
          popperOptions: {
            modifiers: [{name: 'offset', options: {offset: [0, 12]}}]
          },
          text: 'Görüntülemek istediğiniz ödevlerin tarih aralığını buradan seçebilirsiniz.',
          buttons: [
            {
              action() {
                return this.next()
              },
              text: 'İleri'
            }
          ],
          cancelIcon: {
            enabled: 1
          }
        },
        {
          id: 'step-3',
          title: 'Yardım Sihirbazı',
          attachTo: {
            element: '.odevlerim',
            on: 'bottom'
          },
          popperOptions: {
            modifiers: [{name: 'offset', options: {offset: [0, 12]}}]
          },
          text: 'Tüm ödevleri buradan görüntüleyebilirsiniz. Yaptığınız ve yapmadığınız testleri ayrı ayrı görüntülemek isterseniz sağdaki butonları kullanabilirsiniz.',
          buttons: [
            {
              action() {
                return this.back()
              },
              classes: 'shepherd-button-secondary',
              text: 'Geri'
            },
            {
              action() {
                this.next()
                return
              },
              text: 'İleri'
            }
          ],
          cancelIcon: {
            enabled: 1
          }
        },
        {
          id: 'step-4',
          title: 'Yardım Sihirbazı',
          attachTo: {
            element: '.tooltip-target-test-0',
            on: 'bottom'
          },
          popperOptions: {
            modifiers: [{name: 'offset', options: {offset: [0, 12]}}]
          },
          text: 'Bu derse ait ödevdeki toplam test sayısını gösterir. Butonun üzerine gelerek işaretlenmiş ve işaretlenmemiş testlerin sayısını ayrı ayrı görebilirsiniz.',
          buttons: [
            {
              action() {
                return this.back()
              },
              classes: 'shepherd-button-secondary',
              text: 'Geri'
            },
            {
              action() {
                this.next()
                return
              },
              text: 'İleri'
            }
          ],
          cancelIcon: {
            enabled: 1
          }
        },
        {
          id: 'step-5',
          title: 'Yardım Sihirbazı',
          attachTo: {
            element: '.tooltip-target-question-0',
            on: 'bottom'
          },
          popperOptions: {
            modifiers: [{name: 'offset', options: {offset: [0, 12]}}]
          },
          text: 'Bu derse ait ödevdeki toplam soru sayısını gösterir. Butonun üzerine gelerek işaretlenmiş ve işaretlenmemiş soruların sayısını ayrı ayrı görebilirsiniz.',
          buttons: [
            {
              action() {
                return this.back()
              },
              classes: 'shepherd-button-secondary',
              text: 'Geri'
            },
            {
              action() {
                this.next()
                return
              },
              text: 'İleri'
            }
          ],
          cancelIcon: {
            enabled: 1
          }
        },
        {
          id: 'step-6',
          title: 'Yardım Sihirbazı',
          attachTo: {
            element: '.ayrinti',
            on: 'bottom'
          },
          popperOptions: {
            modifiers: [{name: 'offset', options: {offset: [0, 12]}}]
          },
          text: 'Bu ödevin ayrıntılarına buradan gidebilirsiniz.',
          buttons: [
            {
              action() {
                this.next()
                return
              },
              text: 'İleri'
            }
          ],
          cancelIcon: {
            enabled: 1
          }
        },
        {
          id: 'step-7',
          title: 'Yardım Sihirbazı',
          attachTo: {
            element: '.extra',
            on: 'bottom'
          },
          popperOptions: {
            modifiers: [{name: 'offset', options: {offset: [0, 12]}}]
          },
          text: 'Bu dersin ödevi birden fazla kaynak içeriyorsa ayrı ayrı listelenmiş şekilde görünecektir.',
          buttons: [
            {
              action() {
                this.next()
                return
              },
              text: 'İleri'
            }
          ],
          cancelIcon: {
            enabled: 1
          }
        },
        {
          id: 'step-8',
          title: 'Yardım Sihirbazı',
          attachTo: {
            element: '.test-0',
            on: 'bottom'
          },
          popperOptions: {
            modifiers: [{name: 'offset', options: {offset: [0, 12]}}]
          },
          text: 'Her kaynağın içerisinde testler bu şekilde ayrı ayrı listelenmiştir. Üstlerine tıklayarak cevaplarınızı girebileceğiniz ekrana ulaşabilirsiniz.',
          buttons: [
            {
              action() {
                this.next()
                return
              },
              text: 'İleri'
            }
          ],
          cancelIcon: {
            enabled: 1
          }
        },
        {
          id: 'step-9',
          title: 'Yardım Sihirbazı',
          attachTo: {
            element: '.tarihegore',
            on: 'bottom'
          },
          popperOptions: {
            modifiers: [{name: 'offset', options: {offset: [0, 12]}}]
          },
          text: 'Ödevleri tarih sırasına göre sıralamak için burayı kullanabilirsiniz.',
          buttons: [
            {
              action() {
                this.complete()
                return
              },
              text: 'Bitti'
            }
          ],
          cancelIcon: {
            enabled: 1
          }
        }
      ],
      tourSteps2: [
        {
          id: 'step-1',
          title: 'Yardım Sihirbazı',
          attachTo: {
            element: '.takvim',
            on: 'bottom'
          },
          popperOptions: {
            modifiers: [{name: 'offset', options: {offset: [0, 12]}}]
          },
          text: 'Görüntülemek istediğiniz ödevlerin tarih aralığını buradan seçebilirsiniz.',
          buttons: [
            {
              action() {
                return this.next()
              },
              text: 'İleri'
            }
          ],
          cancelIcon: {
            enabled: 1
          }
        },
        {
          title: 'Yardım Sihirbazı',
          attachTo: {
            element: '.tarihegore',
            on: 'bottom'
          },
          popperOptions: {
            modifiers: [{name: 'offset', options: {offset: [0, 12]}}]
          },
          text: 'Ödevleri tarih sırasına göre sıralamak için burayı kullanabilirsiniz.',
          buttons: [
            {
              action() {
                return this.back()
              },
              classes: 'shepherd-button-secondary',
              text: 'Geri'
            },
            {
              action() {
                this.next()
                return
              },
              text: 'İleri'
            }
          ],
          cancelIcon: {
            enabled: 1
          }
        },
        {
          title: 'Yardım Sihirbazı',
          attachTo: {
            element: '.odevlerim',
            on: 'bottom'
          },
          popperOptions: {
            modifiers: [{name: 'offset', options: {offset: [0, 12]}}]
          },
          text: 'Tüm ödevleri buradan görüntüleyebilirsiniz. Yaptığınız ve yapmadığınız testleri ayrı ayrı görüntülemek isterseniz sağdaki butonları kullanabilirsiniz.',
          buttons: [
            {
              action() {
                return this.back()
              },
              classes: 'shepherd-button-secondary',
              text: 'Geri'
            },
            {
              action() {
                this.complete()
                return
              },
              text: 'Bitti'
            }
          ],
          cancelIcon: {
            enabled: 1
          }
        }
      ]
    }
  },
  components: {HomeworkPlaningWidget, HomeworkAnswersWidget},
  watch: {
    screenLevel(newValue, oldValue) {
      if (newValue === 2 && oldValue === 3) {
        if (
            (this.sourceTestResult.CorrectCount > 0 || this.sourceTestResult.WrongCount > 0) &&
            this.homeworks.length > 0
        ) {
          this.homeworks.find(
              (s) => s.HomeworkId === this.sourceTestResult.HomeworkId
          ).SourceTestResult = {}
          Object.assign(
              this.homeworks.find((s) => s.HomeworkId === this.sourceTestResult.HomeworkId)
                  .SourceTestResult,
              this.sourceTestResult
          )
        }
        this.homeworkSync()
        this.editedItem = {}
      }
      this.$vuetify.goTo('#first', this.scrollOptions)
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM dddd') : ''
    },
    momentDate: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM') : ''
    }
  },
  mounted() {
    if (this.$route.name == 'odevlerim') {
      var date = this.getMonday(new Date())
      this.dates[0] = date.toISOString().slice(0, 10)
      this.dates[1] = date.addDays(6).toISOString().slice(0, 10)
    }
    this.getHomeworksFromApi()
  },
  created() {
    this.getTomorrowHomeworkSources()
  },
  methods: {
    iActuallyDidThisHomework(item) {
      this.loadingAnswer = true
      let model = {
        HomeworkId: item.HomeworkId,
        IsDone: !item.IsHomeworkStudentAnswerDone
      }
      ApiService.setHeader()
      ApiService.put('api/Homework/ididmyhomework', model)
          .then(() => {
            item.IsHomeworkStudentAnswerDone = !item.IsHomeworkStudentAnswerDone
            this.loadingAnswer = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loadingAnswer = false
          })
    },
    moment: function () {
      return moment()
    },
    getColorCode(val1, val2) {
      var perc = val1 / (val1 + val2)
      if (perc == 0) return '#F01A00'
      else if (perc == 1) return '#1EAE23'
      else if (perc < 0.25) return '#FB6422'
      else if (perc < 0.5) return '#FCB020'
      else if (perc < 0.75) return '#F8DC23'
      else if (perc < 1) return '#B8D41F'
    },
    getHomeworksFromApi() {
      this.loading = true
      if (this.dates[0] && this.dates[1] && this.$route.name == 'odevlerim') {
        var g1 = new Date(this.dates[0])
        var g2 = new Date(this.dates[1])
        if (g2.getTime() < g1.getTime()) {
          var tmpDate = this.dates[0]
          this.dates[0] = this.dates[1]
          this.dates[1] = tmpDate
        }
      }
      var model = {
        StartDate: this.dates[0],
        EndDate: this.dates[1],
        IsCompleted: this.isCompleted,
        IsNotCompleted: this.isNotCompleted,
        IsCurrent: this.isCurrent,
        OrderByCreatedDate: this.orderByCreatedDate,
        WithoutManualHomeworks: true
      }

      ApiService.setHeader()
      ApiService.post('api/Homework/HomeworkSourceTest', model)
          .then((data) => {
            this.loading = false
            this.homeworks = data.data
            this.homeworkSync()
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    },
    homeworkSync() {
      this.homeworksByLesson = this.groupByArray(this.homeworks, 'LessonName')

      var values = this.homeworksByLesson.map((s) => {
        return s.values
      })
      this.homeworkByLessonSum = {
        answeredQuestionCount: 0,
        answeredTestCount: 0,
        unAnsweredQuestionCount: 0,
        unAnsweredTestCount: 0,
        correctCount: 0,
        wrongCount: 0,
        emptyCount: 0,
        scy: 0
      }
      for (let index = 0; index < values.length; index++) {
        const element = values[index]
        this.homeworksByLesson[index].values = this.groupByArray(element, 'SourceName')
        var answeredTestCount = 0
        var unAnsweredTestCount = 0
        var answeredCount = 0
        var unAnsweredCount = 0
        var endDate = null
        var correctCount = 0
        var wrongCount = 0
        var emptyCount = 0

        for (let i = 0; i < element.length; i++) {
          if (element[i].SourceName == 'Doküman') {
            continue
          }
          answeredCount = answeredCount += element[i].AnsweredQuestionCount
          unAnsweredCount = unAnsweredCount +=
              element[i].QuestionCount - element[i].AnsweredQuestionCount
          endDate = element[i].EndDate
          if (element[i].AnsweredQuestionCount > 0) {
            answeredTestCount++
          } else {
            unAnsweredTestCount++
          }
          if (element[i].SourceTestResult && element[i].SourceTestResult.MarkingCount > 0) {
            correctCount = correctCount += element[i].SourceTestResult.CorrectCount
            wrongCount = wrongCount += element[i].SourceTestResult.WrongCount
            emptyCount = emptyCount += element[i].SourceTestResult.EmptyCount
          }
        }
        this.homeworksByLesson[index].answeredQuestionCount = answeredCount
        this.homeworksByLesson[index].answeredTestCount = answeredTestCount
        this.homeworksByLesson[index].unAnsweredTestCount = unAnsweredTestCount
        this.homeworksByLesson[index].unAnsweredQuestionCount = unAnsweredCount

        this.homeworksByLesson[index].correctCount = correctCount ?? 0
        this.homeworksByLesson[index].wrongCount = wrongCount ?? 0
        this.homeworksByLesson[index].emptyCount = emptyCount ?? 0
        this.homeworksByLesson[index].scy =
            answeredCount > 0
                ? ((correctCount * 100) / (answeredCount + unAnsweredCount)).toFixed(0)
                : 0

        this.homeworkByLessonSum.answeredQuestionCount += answeredCount
        this.homeworkByLessonSum.answeredTestCount += answeredTestCount
        this.homeworkByLessonSum.unAnsweredTestCount += unAnsweredTestCount
        this.homeworkByLessonSum.unAnsweredQuestionCount += unAnsweredCount
        this.homeworkByLessonSum.correctCount += correctCount
        this.homeworkByLessonSum.wrongCount += wrongCount
        this.homeworkByLessonSum.emptyCount += emptyCount
        this.homeworkByLessonSum.scy =
            this.homeworkByLessonSum.answeredQuestionCount > 0
                ? (
                    (this.homeworkByLessonSum.correctCount * 100) /
                    (this.homeworkByLessonSum.answeredQuestionCount +
                        this.homeworkByLessonSum.unAnsweredQuestionCount)
                ).toFixed(0)
                : 0
        this.homeworksByLesson[index].endDate = endDate

        for (let j = 0; j < this.homeworksByLesson[index].values.length; j++) {
          const test = this.homeworksByLesson[index].values[j]
          answeredTestCount = 0
          unAnsweredTestCount = 0
          answeredCount = 0
          unAnsweredCount = 0
          endDate = null
          for (let i = 0; i < test.values.length; i++) {
            answeredCount = answeredCount += test.values[i].AnsweredQuestionCount
            unAnsweredCount = unAnsweredCount +=
                test.values[i].QuestionCount - test.values[i].AnsweredQuestionCount
            endDate = test.values[i].EndDate
            if (test.values[i].AnsweredQuestionCount > 0) {
              answeredTestCount++
            } else {
              unAnsweredTestCount++
            }
          }

          this.homeworksByLesson[index].values[j].answeredQuestionCount = answeredCount
          this.homeworksByLesson[index].values[j].answeredTestCount = answeredTestCount
          this.homeworksByLesson[index].values[j].unAnsweredTestCount = unAnsweredTestCount
          this.homeworksByLesson[index].values[j].unAnsweredQuestionCount = unAnsweredCount
          this.homeworksByLesson[index].values[j].endDate = endDate
        }
      }

      for (let index = 0; index < this.homeworks.length; index++) {
        const element = this.homeworks[index]
        element.progressValue = (element.AnsweredQuestionCount / element.QuestionCount) * 100
      }
    },
    groupByArray(xs, key) {
      return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key]
        let el = rv.find((r) => r && r.key === v)
        if (el) {
          el.values.push(x)
        } else {
          rv.push({key: v, values: [x]})
        }
        return rv
      }, [])
    },
    goToTestAnswer(homeworkId, sourceTestId) {
      this.editedItem = this.homeworks.find(
          (x) => x.SourceTestId == sourceTestId && x.HomeworkId == homeworkId
      )

      this.screenLevel3HomeworkId = homeworkId
      this.screenLevel3SourceTestId = sourceTestId
      this.screenLevel = 3
    },
    goToDocumentAnswer(homeworkId) {
      this.editedItem = this.homeworks.find((x) => x.HomeworkId == homeworkId)
      this.screenLevel3HomeworkId = homeworkId
      this.screenLevel3SourceTestId = 0
      this.screenLevel = 3
    },
    startTour() {
      const tour = this.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
          scrollTo: {
            behavior: 'smooth',
            block: 'center'
          }
        }
      })
      const myStep = tour.addSteps(this.tourSteps)
      tour.on('show', () => {
        if (myStep.currentStep && myStep.currentStep.id == 'step-6') {
          document.getElementById('buttonLesson0').click()
        }
      })

      tour.on('show', () => {
        if (myStep.currentStep && myStep.currentStep.id == 'step-8') {
          document.getElementById('geri').click()
        }
      })

      // tour.on('back', () => {
      //   if (myStep.currentStep && myStep.currentStep.id == 'step-7') {
      //     this.screenLevel = 1
      //   }
      // })

      tour.start()
    },
    startTour2() {
      const tour = this.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
          scrollTo: {
            behavior: 'smooth',
            block: 'center'
          }
        }
      })
      tour.addSteps(this.tourSteps2)
      tour.start()
    },
    previousWeek() {
      // başlangıç tarihine göre haftanın pazartesi gününü bul
      var d = new Date(this.dates[0])
      d = this.getMonday(d.isValid() ? d : new Date())

      d = d.addDays(-7)
      this.dates[0] = d.toISOString().slice(0, 10)
      //pazar gününü bul

      this.dates[1] = d.addDays(6).toISOString().slice(0, 10)
      this.getHomeworksFromApi()
    },
    nextWeek() {
      // başlangıç tarihine göre haftanın pazartesi gününü bul
      var d = new Date(this.dates[0])
      d = this.getMonday(d.isValid() ? d : new Date())

      d = d.addDays(7)
      this.dates[0] = d.toISOString().slice(0, 10)
      //pazar gününü bul
      this.dates[1] = d.addDays(6).toISOString().slice(0, 10)

      this.getHomeworksFromApi()
    },
    getMonday(d) {
      d = new Date(d)
      var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
      return new Date(d.setDate(diff))
    },

    getTomorrowHomeworkSources() {
      ApiService.setHeader()
      ApiService.post('api/Homework/tomorrow-homework-source-tests', {})
          .then((data) => {
            this.tomorrowSourceTests = data.data
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    }
  }
}
</script>
