<template>
  <v-card flat id="first">
    <v-card-title class="px-1 mx-md-3 pb-0">
      <b-btn
        size="sm"
        :variant="!isCompleted && !isNotCompleted && !isIncomplete ? 'primary' : ''"
        class="mx-0 px-1 p-sm-2 odevlerim"
        @click="
          isCompleted = false
          isNotCompleted = false
          isIncomplete = false
          getHomeworksFromApi()
          screenLevel = 1
        "
        >Ödevlerim
      </b-btn>
      <b-btn
        size="sm"
        class="mx-1 mx-sm-2 px-1 p-sm-2 yaptiklarim"
        :variant="isCompleted && !isNotCompleted ? 'primary' : ''"
        @click="
          isCompleted = true
          isNotCompleted = false
          isIncomplete = false
          getHomeworksFromApi()
          screenLevel = 1
        "
        >Yaptıklarım
      </b-btn>
      <b-btn
        size="sm"
        class="mx-0 px-1 p-sm-2 yapmadiklarim"
        :variant="!isCompleted && isNotCompleted ? 'primary' : ''"
        @click="
          isCompleted = false
          isNotCompleted = true
          isIncomplete = false
          getHomeworksFromApi()
          screenLevel = 1
        "
        >Yapmadıklarım
      </b-btn>
      <b-btn
        size="sm"
        class="mx-1 mx-sm-2 px-1 p-sm-2"
        :variant="isIncomplete ? 'primary' : ''"
        @click="
          isCompleted = false
          isNotCompleted = false
          isIncomplete = true
          getHomeworksFromApi()
          screenLevel = 1
        "
        >Eksik Yaptıklarım
      </b-btn>
      <v-dialog
        ref="dialog"
        v-model="dateDialog"
        :return-value.sync="/* eslint-disable vue/no-mutating-props */ dates"
        persistent
        width="290px"
      >
        <v-card>
          <v-date-picker
            v-model="/* eslint-disable vue/no-mutating-props */ dates"
            scrollable
            range
            color="green lighten-1"
            header-color="primary"
            locale="tr"
            min="2021-09-13"
            first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateDialog = false"> İPTAL</v-btn>
            <v-btn
              text
              color="primary"
              @click="
                getHomeworksFromApi()
                $refs.dialog.save(dates)
              "
            >
              ARA
            </v-btn>
          </v-date-picker>
          <v-radio-group class="mx-3" v-model="orderByCreatedDate">
            <v-radio label="Son Kontrol Tarihine Göre" :value="false"></v-radio>
            <v-radio label="Ödev Yapma Tarihine Göre" :value="true"></v-radio>
          </v-radio-group>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      color="primary"
      class="mb-0"
    ></v-progress-linear>
    <v-card-text>
      <div v-if="$route.name == 'odevlerim' && (screenLevel == 1 || screenLevel == 2)">
        <div class="d-flex justify-content-around w-100 mb-2">
          <v-btn small icon class="" @click="previousWeek">
            <v-icon left> mdi-chevron-left</v-icon>
            ÖNCEKİ
          </v-btn>
          <v-toolbar-title>
            <div class="d-flex justify-center align-center">
              {{ dates[0] | momentDate }} - {{ dates[1] | momentDate }}
              <v-btn class="float-right ml-1 takvim" icon small @click="dateDialog = true">
                <v-icon> mdi-calendar</v-icon>
              </v-btn>
            </div>
          </v-toolbar-title>
          <v-btn small icon class="" @click="nextWeek">
            SONRAKİ
            <v-icon right> mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>

      <div v-if="screenLevel == 1">
        <div class="table-responsive d-none d-sm-block">
          <table class="table table-hover table-vertical-center mt-2">
            <thead>
              <tr class="text-center">
                <th class="text-start" scope="col">Ders</th>
                <th scope="col">Ödev Sayısı</th>
                <th scope="col">Yapılan Ödev Sayısı</th>
                <th scope="col">Yapılmayan Ödev Sayısı</th>
                <th scope="col">Eksik Yapılan Ödev Sayısı</th>
                <th scope="col">Ödevin Ayrıntısı</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" v-if="loading">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </tr>
              <template v-else v-for="(element, index) in homeworksByLesson">
                <tr class="text-center" v-bind:key="index">
                  <td class="text-start" style="max-width: 130px">
                    <span>{{ element.key }}<br /> </span>
                  </td>
                  <td>
                    {{
                      element.unAnsweredManualHomeworkCount +
                      element.answeredManualHomeworkCount +
                      element.incompleteManualHomeworkCount
                    }}
                  </td>
                  <td>
                    {{ element.answeredManualHomeworkCount }}
                  </td>
                  <td>
                    {{ element.unAnsweredManualHomeworkCount }}
                  </td>
                  <td>{{ element.incompleteManualHomeworkCount }}</td>
                  <td>
                    <b-button
                      size="sm"
                      variant="outline-primary"
                      v-b-tooltip.hover.left="'Ödevin Ayrıntısı'"
                      :id="'buttonLesson' + index"
                      @click="
                        screenLevel = 2
                        screenLevel2Key = index
                      "
                    >
                      <i class="ki ki-long-arrow-next icon-nm m-0 p-0"></i>
                    </b-button>
                  </td>
                </tr>
              </template>
              <tr class="text-center">
                <td class="text-start">TOPLAM</td>
                <td>
                  {{
                    homeworkByLessonSum.answeredManualHomeworkCount +
                    homeworkByLessonSum.unAnsweredManualHomeworkCount +
                    homeworkByLessonSum.incompleteManualHomeworkCount
                  }}
                </td>
                <td>
                  {{ homeworkByLessonSum.answeredManualHomeworkCount }}
                </td>
                <td>
                  {{ homeworkByLessonSum.unAnsweredManualHomeworkCount }}
                </td>
                <td>{{ homeworkByLessonSum.incompleteManualHomeworkCount }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-for="(element, index) in homeworksByLesson"
          :key="element.key"
          class="d-flex mt-0 d-sm-none"
        >
          <div class="text-left solsutun">
            <p class="text-dark-75 font-weight-bolder font-size-lg">
              {{ element.key }}
            </p>
            <p class="text-muted font-weight-bold"></p>
          </div>

          <div class="text-left flex-grow-1 testsoru ml-2">
            <span class="font-weight-bolder">
              <b-badge
                v-if="
                  element.unAnsweredManualHomeworkCount > 0 ||
                  element.answeredManualHomeworkCount > 0
                "
                pill
                :class="'tooltip-target-test-' + index"
                light
                :style="
                  'color: #fff;background-color:' +
                  getColorCode(
                    element.answeredManualHomeworkCount,
                    element.unAnsweredManualHomeworkCount
                  )
                "
                :id="'tooltip-target-test-' + index"
                >{{
                  element.answeredManualHomeworkCount + element.unAnsweredManualHomeworkCount
                }}
                Ödev</b-badge
              >
            </span>
          </div>
          <div class="text-right ayrinti mt-2">
            <b-button
              size="sm"
              class=""
              variant="outline-primary"
              v-b-tooltip.hover.left="'Ödevin Ayrıntısı'"
              :id="'buttonLesson' + index"
              @click="
                screenLevel = 2
                screenLevel2Key = index
              "
            >
              <i class="ki ki-long-arrow-next icon-nm m-0 p-0"></i>
            </b-button>
          </div>
        </div>

        <div class="d-flex mt-0 d-sm-none" v-if="homeworksByLesson.length > 0">
          <div class="text-left">
            <p class="text-dark-75 font-weight-bolder mt-1 mr-1 font-size-lg">Toplam</p>
          </div>
          <div class="text-left flex-grow-1">
            <b-badge
              v-if="
                homeworkByLessonSum.answeredManualHomeworkCount +
                  homeworkByLessonSum.unAnsweredManualHomeworkCount >
                0
              "
              pill
              light
              class="m-1"
              :style="
                'color: #fff;background-color:' +
                getColorCode(
                  homeworkByLessonSum.answeredManualHomeworkCount,
                  homeworkByLessonSum.unAnsweredManualHomeworkCount
                )
              "
              id="tooltip-target-test-sum"
              >{{
                homeworkByLessonSum.answeredManualHomeworkCount +
                homeworkByLessonSum.unAnsweredManualHomeworkCount
              }}
              Ödev
            </b-badge>
          </div>
          <div class="text-right"></div>
        </div>
      </div>
      <div v-if="screenLevel == 2">
        <div class="row my-2 mx-sm-2">
          <b-button
            size="sm"
            class="mx-3 mt-0 geri"
            id="geri"
            variant="outline-primary"
            @click="
              screenLevel = 1
              screenLevel2Key = null
            "
          >
            <b-icon icon="arrow90deg-left"></b-icon>
          </b-button>
          <h3 class="mt-1">{{ homeworksByLesson[screenLevel2Key].key }}</h3>
        </div>
        <div
          class="row mt-4 m-sm-5"
          :class="
            homeworksByLesson[screenLevel2Key].values.length > 1
              ? 'border border-primary rounded'
              : ''
          "
          v-for="(item, i) in homeworksByLesson[screenLevel2Key].values"
          :key="item.key"
        >
          <div class="col-12 m-0 p-0">
            <b-collapse visible :id="'innercollapse-' + screenLevel2Key + '-' + i" class="row m-1">
              <div
                v-for="(thing, idx) in item.values"
                :key="idx"
                class="col-sm-6 col-md-4"
                :class="'test-' + idx"
              >
                <div class="border rounded p-4">
                  <a
                    :id="'adocument-' + idx"
                    class="text-gray-800 text-hover-primary fw-bolder fs-6"
                    @click="goToDocumentAnswer(thing.HomeworkId)"
                  >
                    <b-icon icon="file-earmark-check" aria-hidden="true"></b-icon>
                    {{ thing.HomeworkName }}
                    <v-chip class="ml-1" x-small>{{ thing.EndDate | moment }}</v-chip>
                  </a>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
      <div v-if="screenLevel == 3">
        <ManualHomeworkAnswersWidget
          :homework="editedItem"
          :homeworkId="screenLevel3HomeworkId"
          v-model="screenLevel"
          :isStrict="editedItem.IsStrict"
          :endDate="editedItem.EndDate"
        />
      </div>
      <div v-if="homeworksByLesson.length == 0" class="text-center">
        {{ dates[0] | moment }} - <span v-if="dates[1]"> {{ dates[1] | moment }}</span>
        <span v-else>∞</span>
        Tarihlerinde ödeviniz bulunmamaktadır.
        <p>Üstte bulunan takvim ikonuna tıklayarak tarih filtresini kullanabilirsiniz.</p>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
/* eslint-disable vue/no-mutating-props */

import ApiService from '@/core/services/api.service.js'
import moment from 'moment'
import ManualHomeworkAnswersWidget from '@/view/pages/dashboard_widgets/ManualHomeworkAnswersWidget.vue'

export default {
  props: ['dates'],
  data() {
    return {
      loading: false,
      loadingAnswer: false,
      homeworks: [],
      isCompleted: false,
      dateDialog: false,
      tab: null,
      homeworksByLesson: [],
      isNotCompleted: false,
      isIncomplete: false,
      screenLevel: 1,
      screenLevel2Key: null,
      innercollapseOpenedKey: null,
      screenLevel3SourceTestId: null,
      screenLevel3HomeworkId: null,
      scrollOptions: { duration: 500, offset: 0, easing: 'easeInOutCubic' },
      homeworkByLessonSum: {},
      editedItem: {},
      sourceTestResult: {},
      orderByCreatedDate: false
    }
  },
  components: { ManualHomeworkAnswersWidget },
  watch: {
    screenLevel(newValue, oldValue) {
      if (newValue === 2 && oldValue === 3) {
        if (
          (this.sourceTestResult.CorrectCount > 0 || this.sourceTestResult.WrongCount > 0) &&
          this.homeworks.length > 0
        ) {
          Object.assign(
            this.homeworks.find((s) => s.HomeworkId === this.sourceTestResult.HomeworkId)
              .SourceTestResult,
            this.sourceTestResult
          )
        }
        this.homeworkSync()
        this.editedItem = {}
      }
      // this.$vuetify.goTo('#first', this.scrollOptions)
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM dddd') : ''
    },
    momentDate: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM') : ''
    }
  },
  mounted() {
    this.getHomeworksFromApi()
  },
  methods: {
    moment: function () {
      return moment()
    },
    getColorCode(val1, val2) {
      var perc = val1 / (val1 + val2)
      if (perc == 0) return '#F01A00'
      else if (perc == 1) return '#1EAE23'
      else if (perc < 0.25) return '#FB6422'
      else if (perc < 0.5) return '#FCB020'
      else if (perc < 0.75) return '#F8DC23'
      else if (perc < 1) return '#B8D41F'
    },
    getHomeworksFromApi() {
      this.loading = true
      if (this.dates[0] && this.dates[1] && this.$route.name == 'odevlerim') {
        var g1 = new Date(this.dates[0])
        var g2 = new Date(this.dates[1])
        if (g2.getTime() < g1.getTime()) {
          var tmpDate = this.dates[0]
          this.dates[0] = this.dates[1]
          this.dates[1] = tmpDate
        }
      }
      var model = {
        StartDate: this.dates[0],
        EndDate: this.dates[1],
        IsCompleted: this.isCompleted,
        IsNotCompleted: this.isNotCompleted,
        IsIncomplete: this.isIncomplete,
        OrderByCreatedDate: this.orderByCreatedDate
      }
      ApiService.setHeader()
      ApiService.post('api/Homework/ManualHomeworkStudent', model)
        .then((data) => {
          this.loading = false
          this.homeworks = data.data
          this.homeworkSync()
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          this.loading = false
        })
    },
    homeworkSync() {
      this.homeworksByLesson = this.groupByArray(this.homeworks, 'LessonName')

      var values = this.homeworksByLesson.map((s) => {
        return s.values
      })
      this.homeworkByLessonSum = {
        answeredManualHomeworkCount: 0,
        unAnsweredManualHomeworkCount: 0,
        incompleteManualHomeworkCount: 0
      }
      for (let index = 0; index < values.length; index++) {
        const element = values[index]
        this.homeworksByLesson[index].values = this.groupByArray(element, 'SourceName')
        var endDate = null
        var answeredManualHomeworkCount = 0
        var unAnsweredManualHomeworkCount = 0
        var incompleteManualHomeworkCount = 0

        for (let i = 0; i < element.length; i++) {
          if (element[i].IsIncomplete) {
            incompleteManualHomeworkCount += 1
          } else {
            if (element[i].IsManualHomeworkAnswered) {
              answeredManualHomeworkCount += 1
            } else {
              unAnsweredManualHomeworkCount += 1
            }
          }

          this.homeworksByLesson[index].answeredManualHomeworkCount = answeredManualHomeworkCount
          this.homeworksByLesson[index].incompleteManualHomeworkCount =
            incompleteManualHomeworkCount
          this.homeworksByLesson[index].unAnsweredManualHomeworkCount =
            unAnsweredManualHomeworkCount

          endDate = element[i].EndDate
        }

        this.homeworksByLesson[index].endDate = endDate
        this.homeworkByLessonSum.answeredManualHomeworkCount += answeredManualHomeworkCount
        this.homeworkByLessonSum.unAnsweredManualHomeworkCount += unAnsweredManualHomeworkCount
        this.homeworkByLessonSum.incompleteManualHomeworkCount += incompleteManualHomeworkCount

        for (let j = 0; j < this.homeworksByLesson[index].values.length; j++) {
          const test = this.homeworksByLesson[index].values[j]
          endDate = null
          for (let i = 0; i < test.values.length; i++) {
            endDate = test.values[i].EndDate
          }

          this.homeworksByLesson[index].values[j].endDate = endDate
        }
      }
    },
    groupByArray(xs, key) {
      return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key]
        let el = rv.find((r) => r && r.key === v)
        if (el) {
          el.values.push(x)
        } else {
          rv.push({ key: v, values: [x] })
        }
        return rv
      }, [])
    },

    goToDocumentAnswer(homeworkId) {
      this.editedItem = this.homeworks.find((x) => x.HomeworkId == homeworkId)
      this.screenLevel3HomeworkId = homeworkId
      this.screenLevel3SourceTestId = 0
      this.screenLevel = 3
    },
    previousWeek() {
      // başlangıç tarihine göre haftanın pazartesi gününü bul
      var d = new Date(this.dates[0])
      d = this.getMonday(d.isValid() ? d : new Date())

      d = d.addDays(-7)
      this.dates[0] = d.toISOString().slice(0, 10)
      //pazar gününü bul

      this.dates[1] = d.addDays(6).toISOString().slice(0, 10)
      this.getHomeworksFromApi()
    },
    nextWeek() {
      // başlangıç tarihine göre haftanın pazartesi gününü bul
      var d = new Date(this.dates[0])
      d = this.getMonday(d.isValid() ? d : new Date())

      d = d.addDays(7)
      this.dates[0] = d.toISOString().slice(0, 10)
      //pazar gününü bul
      this.dates[1] = d.addDays(6).toISOString().slice(0, 10)

      this.getHomeworksFromApi()
    },
    getMonday(d) {
      d = new Date(d)
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
      return new Date(d.setDate(diff))
    }
  }
}
</script>
