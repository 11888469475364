var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-10", attrs: { align: "center" } },
    [
      _c(
        "b-card",
        {
          staticClass: "p-2",
          staticStyle: { "max-width": "360px" },
          attrs: { "no-body": "" },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { size: "sm", variant: "outline-primary" },
                    on: {
                      click: function ($event) {
                        _vm.$emit("update:inputData", _vm.inputData)
                        _vm.$emit("input", 2)
                      },
                    },
                  },
                  [
                    _c("b-icon", { attrs: { icon: "arrow90deg-left" } }),
                    _vm._v(" Ödev Listesine Dön "),
                  ],
                  1
                ),
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "360px" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "b-button",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "ml-2",
                                      attrs: { size: "sm", variant: "primary" },
                                    },
                                    "b-button",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" Kazanımlar ")]
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.dialog,
                        callback: function ($$v) {
                          _vm.dialog = $$v
                        },
                        expression: "dialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", { staticClass: "text-h5" }, [
                            _vm._v(" Kazanımlar "),
                          ]),
                          _c(
                            "v-card-text",
                            _vm._l(
                              _vm.sourceTest.Achievements2,
                              function (i, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    _vm._v(
                                      " " + _vm._s(i.LessonUnitName) + " "
                                    ),
                                    _c("br"),
                                    _vm._v(" " + _vm._s(i.ParentSubjectName)),
                                    _c("br"),
                                    _vm._v(" " + _vm._s(i.ChildSubjectName)),
                                    _c("br"),
                                    _vm._v(
                                      " " + _vm._s(i.ParentAchievementName)
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " + _vm._s(i.ChildAchievementName)
                                    ),
                                    _c("br"),
                                    _c("v-divider"),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
            _c("div", { staticClass: "col-12 text-center" }, [
              _c("h6", [
                _vm._v(
                  "Son Kontrol Tarihi: " +
                    _vm._s(_vm._f("momentDate")(_vm.endDate)) +
                    " Saat: " +
                    _vm._s(_vm._f("moment")(_vm.endDate))
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-12 text-center" },
              [
                _c("h6", [_vm._v(_vm._s(_vm.sourceTest.SourceName))]),
                _c("h6", { staticClass: "font-weight-normal" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.sourceTest.SourceChapterName) +
                      " - " +
                      _vm._s(_vm.sourceTest.Name) +
                      " "
                  ),
                ]),
                _c("v-spacer"),
                _c(
                  "v-alert",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLocked,
                        expression: "isLocked",
                      },
                    ],
                    attrs: { type: "warning", color: "orange" },
                  },
                  [
                    _vm._v(
                      "Ödev kontrol tarihi geçtiği için öğretmenimiz, soruların cevaplarının işaretlenmesini kapatmıştır. Ödevinizi tamamladıysanız, lütfen öğretmenimizle iletişime geçiniz."
                    ),
                  ]
                ),
                _c("h6", { staticClass: "font-weight-normal" }, [
                  _vm._v("CEVAPLARI"),
                ]),
              ],
              1
            ),
          ]),
          _vm._l(_vm.homeworkAnswers, function (item, index) {
            return _c("div", { key: index }, [
              _c("div", { staticClass: "mt-3 d-flex justify-content-center" }, [
                _c("div", { staticClass: "text-center mt-2" }, [
                  _vm._v(_vm._s(item.QuestionNumber.toString()) + ")  "),
                ]),
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "b-form-radio-group",
                      {
                        attrs: {
                          size: "md",
                          name: "radio-btn-outline",
                          buttons: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.saveTestAnswerToList(item)
                          },
                        },
                        model: {
                          value: item.TestOptionTypeId,
                          callback: function ($$v) {
                            _vm.$set(item, "TestOptionTypeId", $$v)
                          },
                          expression: "item.TestOptionTypeId",
                        },
                      },
                      _vm._l(_vm.testOptionTypes, function (option) {
                        return _c(
                          "b-form-radio",
                          {
                            key: option.Id,
                            attrs: {
                              value: option.Id,
                              name: option.Name,
                              disabled:
                                option.Id != item.TestOptionTypeId
                                  ? option.disabled
                                  : false,
                              "button-variant": item.color
                                ? item.color
                                : "outline-primary",
                            },
                          },
                          [_vm._v(" " + _vm._s(option.Name) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ])
          }),
          _c(
            "div",
            { staticClass: "text-center m-3" },
            [
              _vm.correctCount === 0 && _vm.wrongCount === 0
                ? _c(
                    "b-progress",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        max: _vm.progressMax,
                        variant: "primary",
                        "show-progress": "",
                      },
                    },
                    [
                      _c("b-progress-bar", {
                        attrs: {
                          value: _vm.answeredQuestionCount,
                          label: `${(
                            (_vm.answeredQuestionCount / _vm.progressMax) *
                            100
                          ).toFixed(0)}%`,
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "b-progress",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover",
                          value:
                            _vm.correctCount +
                            " D / " +
                            _vm.wrongCount +
                            " Y / " +
                            _vm.emptyCount +
                            " B",
                          expression:
                            "correctCount + ' D / ' + wrongCount + ' Y / ' + emptyCount + ' B'",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "mt-3",
                      attrs: { max: _vm.progressMax, "show-value": "" },
                    },
                    [
                      _c("b-progress-bar", {
                        attrs: { value: _vm.correctCount, variant: "success" },
                      }),
                      _c("b-progress-bar", {
                        attrs: { value: _vm.wrongCount, variant: "danger" },
                      }),
                      _c("b-progress-bar", {
                        attrs: { value: _vm.emptyCount, variant: "secondary" },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
          !_vm.isLocked
            ? _c(
                "div",
                { staticClass: "text-center m-3" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-3",
                      attrs: { variant: "primary" },
                      on: { click: _vm.saveTestAnswersBulk },
                    },
                    [_vm._v("KAYDET")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }