var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "", id: "first" } },
    [
      _c(
        "v-card-title",
        { staticClass: "px-1 mx-md-3 pb-3" },
        [
          _c(
            "b-btn",
            {
              staticClass: "mx-0 px-1 p-sm-2 mt-1 odevlerim",
              attrs: {
                size: "sm",
                variant:
                  !_vm.isCompleted && !_vm.isNotCompleted && _vm.isCurrent
                    ? "primary"
                    : "",
              },
              on: {
                click: function ($event) {
                  _vm.isCompleted = false
                  _vm.isNotCompleted = false
                  _vm.isCurrent = true
                  _vm.getHomeworksFromApi()
                  _vm.screenLevel = 1
                },
              },
            },
            [_vm._v("Bugün Yapacaklarım ")]
          ),
          _c(
            "b-btn",
            {
              staticClass: "ml-1 ml-sm-2 px-1 p-sm-2 mt-1 odevlerim",
              attrs: {
                size: "sm",
                variant:
                  !_vm.isCompleted && !_vm.isNotCompleted && !_vm.isCurrent
                    ? "primary"
                    : "",
              },
              on: {
                click: function ($event) {
                  _vm.isCompleted = false
                  _vm.isNotCompleted = false
                  _vm.isCurrent = false
                  _vm.getHomeworksFromApi()
                  _vm.screenLevel = 1
                },
              },
            },
            [_vm._v("Ödevlerim ")]
          ),
          _c(
            "b-btn",
            {
              staticClass: "mx-1 mx-sm-2 px-1 p-sm-2 yaptiklarim mt-1",
              attrs: {
                size: "sm",
                variant:
                  _vm.isCompleted && !_vm.isNotCompleted ? "primary" : "",
              },
              on: {
                click: function ($event) {
                  _vm.isCompleted = true
                  _vm.isNotCompleted = false
                  _vm.isCurrent = false
                  _vm.getHomeworksFromApi()
                  _vm.screenLevel = 1
                },
              },
            },
            [_vm._v("Yaptıklarım ")]
          ),
          _c(
            "b-btn",
            {
              staticClass: "mx-0 px-1 p-sm-2 yapmadiklarim mt-1",
              attrs: {
                size: "sm",
                variant:
                  !_vm.isCompleted && _vm.isNotCompleted ? "primary" : "",
              },
              on: {
                click: function ($event) {
                  _vm.isCompleted = false
                  _vm.isNotCompleted = true
                  _vm.isCurrent = false
                  _vm.getHomeworksFromApi()
                  _vm.screenLevel = 1
                },
              },
            },
            [_vm._v("Yapmadıklarım ")]
          ),
          _c("v-spacer"),
          _vm.isCurrent &&
          _vm.tomorrowSourceTests.length > 0 &&
          !_vm.$vuetify.breakpoint.mobile
            ? _c(
                "v-tooltip",
                {
                  attrs: { left: "", color: "success" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-chip",
                              _vm._g(
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    small: "",
                                    label: "",
                                    color: "primary",
                                  },
                                },
                                on
                              ),
                              [_vm._v("Yarın Kontrol Edilecek Ödevler")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2276293365
                  ),
                },
                [
                  _c("span", {
                    staticClass: "justify-center d-flex font-weight-boldest",
                  }),
                  _c(
                    "ul",
                    { staticClass: "mt-1" },
                    _vm._l(_vm.tomorrowSourceTests, function (item, index) {
                      return _c("li", { key: index }, [
                        _vm._v(" " + _vm._s(item.SourceName) + " "),
                        _c(
                          "ul",
                          _vm._l(item.SourceTestNames, function (testName) {
                            return _c("li", { key: testName }, [
                              _vm._v(" " + _vm._s(testName) + " "),
                            ])
                          }),
                          0
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.$vuetify.breakpoint.mobile,
                  expression: "!$vuetify.breakpoint.mobile",
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.top",
                  value: "Ödevleri Tarihe Göre Görüntüle",
                  expression: "'Ödevleri Tarihe Göre Görüntüle'",
                  modifiers: { hover: true, top: true },
                },
              ],
              staticClass: "tarihegore",
              attrs: { to: { name: "odevler" }, icon: "", small: "" },
            },
            [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v(" fas fa-sort-amount-down"),
              ]),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              ref: "dialog",
              attrs: {
                "return-value":
                  /* eslint-disable vue/no-mutating-props */ _vm.dates,
                persistent: "",
                width: "290px",
              },
              on: {
                "update:returnValue": function ($event) {
                  /* eslint-disable vue/no-mutating-props */ _vm.dates = $event
                },
                "update:return-value": function ($event) {
                  /* eslint-disable vue/no-mutating-props */ _vm.dates = $event
                },
              },
              model: {
                value: _vm.dateDialog,
                callback: function ($$v) {
                  _vm.dateDialog = $$v
                },
                expression: "dateDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-date-picker",
                    {
                      attrs: {
                        scrollable: "",
                        range: "",
                        color: "green lighten-1",
                        "header-color": "primary",
                        locale: "tr",
                        min: "2021-09-13",
                        "first-day-of-week": "1",
                      },
                      model: {
                        value:
                          /* eslint-disable vue/no-mutating-props */ _vm.dates,
                        callback: function ($$v) {
                          /* eslint-disable vue/no-mutating-props */ _vm.dates =
                            $$v
                        },
                        expression:
                          "/* eslint-disable vue/no-mutating-props */ dates",
                      },
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dateDialog = false
                            },
                          },
                        },
                        [_vm._v(" İPTAL")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.getHomeworksFromApi()
                              _vm.$refs.dialog.save(_vm.dates)
                            },
                          },
                        },
                        [_vm._v(" ARA ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "mx-3",
                      model: {
                        value: _vm.orderByCreatedDate,
                        callback: function ($$v) {
                          _vm.orderByCreatedDate = $$v
                        },
                        expression: "orderByCreatedDate",
                      },
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: "Son Kontrol Tarihine Göre",
                          value: false,
                        },
                      }),
                      _c("v-radio", {
                        attrs: {
                          label: "Ödev Yapma Tarihine Göre",
                          value: true,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-progress-linear", {
        staticClass: "mb-0",
        attrs: {
          active: _vm.loading,
          indeterminate: _vm.loading,
          color: "primary",
        },
      }),
      _c(
        "v-card-text",
        [
          _vm.$route.name == "odevlerim" &&
          (_vm.screenLevel == 1 || _vm.screenLevel == 2)
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-around w-100 mb-2" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { small: "", icon: "" },
                        on: { click: _vm.previousWeek },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v(" mdi-chevron-left"),
                        ]),
                        _vm._v(" ÖNCEKİ "),
                      ],
                      1
                    ),
                    _c("v-toolbar-title", [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center align-center" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("momentDate")(_vm.dates[0])) +
                              " - " +
                              _vm._s(_vm._f("momentDate")(_vm.dates[1])) +
                              " "
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "float-right ml-1 takvim",
                              attrs: { icon: "", small: "" },
                              on: {
                                click: function ($event) {
                                  _vm.dateDialog = true
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v(" mdi-calendar")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "v-btn",
                      {
                        attrs: { small: "", icon: "" },
                        on: { click: _vm.nextWeek },
                      },
                      [
                        _vm._v(" SONRAKİ "),
                        _c("v-icon", { attrs: { right: "" } }, [
                          _vm._v(" mdi-chevron-right"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          !_vm.$vuetify.breakpoint.mobile &&
          !_vm.isCompleted &&
          !_vm.isNotCompleted &&
          _vm.isCurrent
            ? [_c("div", [_c("homework-planing-widget")], 1)]
            : [
                _vm.screenLevel == 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "table-responsive d-none d-sm-block" },
                          [
                            _c(
                              "table",
                              {
                                staticClass:
                                  "table table-hover table-vertical-center mt-2",
                              },
                              [
                                _c("thead", [
                                  _c("tr", { staticClass: "text-center" }, [
                                    _c(
                                      "th",
                                      {
                                        staticClass: "text-start",
                                        attrs: { scope: "col" },
                                      },
                                      [_vm._v("Ders")]
                                    ),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v(" Test "),
                                      _c("br"),
                                      _vm._v(" Sayısı "),
                                    ]),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v(" İşaretleme Yapılmış "),
                                      _c("br"),
                                      _vm._v(" Test Sayısı "),
                                    ]),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v(" İşaretleme Yapılmamış "),
                                      _c("br"),
                                      _vm._v(" Test Sayısı "),
                                    ]),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v(" Soru "),
                                      _c("br"),
                                      _vm._v(" Sayısı "),
                                    ]),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v(" İşaretleme Yapılmış "),
                                      _c("br"),
                                      _vm._v(" Soru Sayısı "),
                                    ]),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v(" İşaretleme Yapılmamış "),
                                      _c("br"),
                                      _vm._v(" Soru Sayısı "),
                                    ]),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v(" Soru "),
                                      _c("br"),
                                      _vm._v(" Çözüm Yüzdesi "),
                                    ]),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v(" Ödevin "),
                                      _c("br"),
                                      _vm._v(" Ayrıntısı "),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  [
                                    _vm.loading
                                      ? _c(
                                          "tr",
                                          { staticClass: "text-center" },
                                          [
                                            _c("b-spinner", {
                                              attrs: {
                                                variant: "primary",
                                                label: "Spinning",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._l(
                                          _vm.homeworksByLesson,
                                          function (element, index) {
                                            return [
                                              _c(
                                                "tr",
                                                {
                                                  key: index,
                                                  staticClass: "text-center",
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-start mt-1",
                                                      staticStyle: {
                                                        "max-width": "130px",
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(element.key) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          element.unAnsweredTestCount +
                                                            element.answeredTestCount
                                                        ) + " "
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          element.answeredTestCount
                                                        ) + " "
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          element.unAnsweredTestCount
                                                        ) + " "
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          element.unAnsweredQuestionCount +
                                                            element.answeredQuestionCount
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          element.answeredQuestionCount
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          element.unAnsweredQuestionCount
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    [
                                                      element.scy != null
                                                        ? _c(
                                                            "b-progress",
                                                            {
                                                              attrs: {
                                                                max: "100",
                                                                variant:
                                                                  "primary",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-progress-bar",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      element.scy,
                                                                    label: `${element.scy}%`,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            element.correctCount +
                                                              " D / " +
                                                              element.wrongCount +
                                                              " Y / " +
                                                              element.emptyCount +
                                                              " B"
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "b-tooltip",
                                                              rawName:
                                                                "v-b-tooltip.hover.left",
                                                              value:
                                                                "Ödevin Ayrıntısı",
                                                              expression:
                                                                "'Ödevin Ayrıntısı'",
                                                              modifiers: {
                                                                hover: true,
                                                                left: true,
                                                              },
                                                            },
                                                          ],
                                                          attrs: {
                                                            size: "sm",
                                                            variant:
                                                              "outline-primary",
                                                            id:
                                                              "buttonLesson" +
                                                              index,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.screenLevel = 2
                                                              _vm.screenLevel2Key =
                                                                index
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "ki ki-long-arrow-next icon-nm m-0 p-0",
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          }
                                        ),
                                    _c("tr", { staticClass: "text-center" }, [
                                      _c("td", { staticClass: "text-start" }, [
                                        _vm._v("TOPLAM"),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.homeworkByLessonSum
                                                .answeredTestCount +
                                                _vm.homeworkByLessonSum
                                                  .unAnsweredTestCount
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.homeworkByLessonSum
                                                .answeredTestCount
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.homeworkByLessonSum
                                                .unAnsweredTestCount
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.homeworkByLessonSum
                                                .unAnsweredQuestionCount +
                                                _vm.homeworkByLessonSum
                                                  .answeredQuestionCount
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.homeworkByLessonSum
                                              .answeredQuestionCount
                                          )
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.homeworkByLessonSum
                                                .unAnsweredQuestionCount
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _vm.homeworkByLessonSum.scy != null
                                            ? _c(
                                                "b-progress",
                                                {
                                                  attrs: {
                                                    max: "100",
                                                    variant: "primary",
                                                  },
                                                },
                                                [
                                                  _c("b-progress-bar", {
                                                    attrs: {
                                                      value:
                                                        _vm.homeworkByLessonSum
                                                          .scy,
                                                      label: `${_vm.homeworkByLessonSum.scy}%`,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.homeworkByLessonSum
                                                  .correctCount +
                                                  " D / " +
                                                  _vm.homeworkByLessonSum
                                                    .wrongCount +
                                                  " Y / " +
                                                  _vm.homeworkByLessonSum
                                                    .emptyCount +
                                                  " B"
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._l(
                          _vm.homeworksByLesson,
                          function (element, index) {
                            return _c(
                              "div",
                              {
                                key: element.key,
                                staticClass:
                                  "d-flex mt-1 d-sm-none align-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "text-left solsutun" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-dark-75 font-weight-bolder font-size-lg",
                                      },
                                      [_vm._v(" " + _vm._s(element.key) + " ")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-left flex-grow-1 testsoru ml-2",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bolder" },
                                      [
                                        element.unAnsweredQuestionCount > 0
                                          ? _c(
                                              "b-badge",
                                              {
                                                class:
                                                  "tooltip-target-test-" +
                                                  index,
                                                style:
                                                  "color: #fff;background-color:" +
                                                  _vm.getColorCode(
                                                    element.answeredTestCount,
                                                    element.unAnsweredTestCount
                                                  ),
                                                attrs: {
                                                  pill: "",
                                                  light: "",
                                                  id:
                                                    "tooltip-target-test-" +
                                                    index,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    element.answeredTestCount +
                                                      element.unAnsweredTestCount
                                                  ) + " Test"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        element.unAnsweredQuestionCount > 0
                                          ? _c(
                                              "b-tooltip",
                                              {
                                                attrs: {
                                                  target:
                                                    "tooltip-target-test-" +
                                                    index,
                                                  triggers: "hover",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " İşaretleme yapılmış test sayısı: " +
                                                    _vm._s(
                                                      element.answeredTestCount
                                                    ) +
                                                    " "
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  " İşaretleme yapılmamış test sayısı: " +
                                                    _vm._s(
                                                      element.unAnsweredTestCount
                                                    ) +
                                                    " "
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  " Oran: %" +
                                                    _vm._s(
                                                      Math.round(
                                                        (element.answeredTestCount /
                                                          (element.answeredTestCount +
                                                            element.unAnsweredTestCount)) *
                                                          100
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        element.unAnsweredQuestionCount > 0
                                          ? _c(
                                              "b-badge",
                                              {
                                                staticClass: "mx-1",
                                                class:
                                                  "tooltip-target-question-" +
                                                  index,
                                                style:
                                                  "color: #fff;background-color:" +
                                                  _vm.getColorCode(
                                                    element.answeredQuestionCount,
                                                    element.unAnsweredQuestionCount
                                                  ),
                                                attrs: {
                                                  pill: "",
                                                  id:
                                                    "tooltip-target-question-" +
                                                    index,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    element.unAnsweredQuestionCount +
                                                      element.answeredQuestionCount
                                                  ) + " Soru"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        element.unAnsweredQuestionCount > 0
                                          ? _c(
                                              "b-tooltip",
                                              {
                                                attrs: {
                                                  target:
                                                    "tooltip-target-question-" +
                                                    index,
                                                  triggers: "hover",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " İşaretlenmiş soru sayısı: " +
                                                    _vm._s(
                                                      element.answeredQuestionCount
                                                    ) +
                                                    " "
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  " İşaretlenmemiş soru sayısı: " +
                                                    _vm._s(
                                                      element.unAnsweredQuestionCount
                                                    ) +
                                                    " "
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  " Oran: %" +
                                                    _vm._s(
                                                      Math.round(
                                                        (element.answeredQuestionCount /
                                                          (element.answeredQuestionCount +
                                                            element.unAnsweredQuestionCount)) *
                                                          100
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "text-right ayrinti mt-2" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover.left",
                                            value: "Ödevin Ayrıntısı",
                                            expression: "'Ödevin Ayrıntısı'",
                                            modifiers: {
                                              hover: true,
                                              left: true,
                                            },
                                          },
                                        ],
                                        attrs: {
                                          size: "sm",
                                          variant: "outline-primary",
                                          id: "buttonLesson" + index,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.screenLevel = 2
                                            _vm.screenLevel2Key = index
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "ki ki-long-arrow-next icon-nm m-0 p-0",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.homeworksByLesson.length > 0
                          ? _c(
                              "div",
                              { staticClass: "d-flex mt-5 d-sm-none" },
                              [
                                _c("div", { staticClass: "text-left mt-1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-dark font-weight-bolder mr-1 font-size-lg",
                                    },
                                    [_vm._v("Toplam")]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "text-left flex-grow-1" },
                                  [
                                    _c(
                                      "b-badge",
                                      {
                                        staticClass: "m-1",
                                        style:
                                          "color: #fff;background-color:" +
                                          _vm.getColorCode(
                                            _vm.homeworkByLessonSum
                                              .answeredTestCount,
                                            _vm.homeworkByLessonSum
                                              .unAnsweredTestCount
                                          ),
                                        attrs: {
                                          pill: "",
                                          light: "",
                                          id: "tooltip-target-test-sum",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.homeworkByLessonSum
                                              .answeredTestCount +
                                              _vm.homeworkByLessonSum
                                                .unAnsweredTestCount
                                          ) + " Test "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "tooltip-target-test-sum",
                                          triggers: "hover",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " İşaretleme yapılmış test sayısı: " +
                                            _vm._s(
                                              _vm.homeworkByLessonSum
                                                .answeredTestCount
                                            ) +
                                            " "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " İşaretleme yapılmamış test sayısı: " +
                                            _vm._s(
                                              _vm.homeworkByLessonSum
                                                .unAnsweredTestCount
                                            ) +
                                            " "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " Oran: %" +
                                            _vm._s(
                                              Math.round(
                                                (_vm.homeworkByLessonSum
                                                  .answeredTestCount /
                                                  (_vm.homeworkByLessonSum
                                                    .answeredTestCount +
                                                    _vm.homeworkByLessonSum
                                                      .unAnsweredTestCount)) *
                                                  100
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-badge",
                                      {
                                        staticClass: "mx-1",
                                        style:
                                          "color: #fff;background-color:" +
                                          _vm.getColorCode(
                                            _vm.homeworkByLessonSum
                                              .answeredQuestionCount,
                                            _vm.homeworkByLessonSum
                                              .unAnsweredQuestionCount
                                          ),
                                        attrs: {
                                          pill: "",
                                          id: "tooltip-target-question-sum",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.homeworkByLessonSum
                                              .unAnsweredQuestionCount +
                                              _vm.homeworkByLessonSum
                                                .answeredQuestionCount
                                          ) + " Soru "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "tooltip-target-question-sum",
                                          triggers: "hover",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " İşaretlenmiş soru sayısı: " +
                                            _vm._s(
                                              _vm.homeworkByLessonSum
                                                .answeredQuestionCount
                                            ) +
                                            " "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " İşaretlenmemiş soru sayısı: " +
                                            _vm._s(
                                              _vm.homeworkByLessonSum
                                                .unAnsweredQuestionCount
                                            ) +
                                            " "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " Oran: %" +
                                            _vm._s(
                                              Math.round(
                                                (_vm.homeworkByLessonSum
                                                  .answeredQuestionCount /
                                                  (_vm.homeworkByLessonSum
                                                    .answeredQuestionCount +
                                                    _vm.homeworkByLessonSum
                                                      .unAnsweredQuestionCount)) *
                                                  100
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.homeworksByLesson.length > 0
                          ? _c(
                              "div",
                              { staticClass: "d-flex mt-2 d-sm-none" },
                              [
                                _c("v-spacer"),
                                _c(
                                  "div",
                                  { staticClass: "text-right" },
                                  [
                                    _vm.isCurrent &&
                                    _vm.tomorrowSourceTests.length > 0
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              left: "",
                                              color: "success",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-chip",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              label: "",
                                                              color: "primary",
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "Yarın Kontrol Edilecek Ödevler"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2975098485
                                            ),
                                          },
                                          [
                                            _c(
                                              "ul",
                                              { staticClass: "mt-1" },
                                              _vm._l(
                                                _vm.tomorrowSourceTests,
                                                function (item, index) {
                                                  return _c(
                                                    "li",
                                                    { key: index },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.SourceName
                                                          ) +
                                                          " "
                                                      ),
                                                      _c(
                                                        "ul",
                                                        _vm._l(
                                                          item.SourceTestNames,
                                                          function (testName) {
                                                            return _c(
                                                              "li",
                                                              { key: testName },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      testName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.screenLevel == 2
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "row my-2 mx-sm-2" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mx-3 mt-0 geri",
                                attrs: {
                                  size: "sm",
                                  id: "geri",
                                  variant: "outline-primary",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.screenLevel = 1
                                    _vm.screenLevel2Key = null
                                  },
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: { icon: "arrow90deg-left" },
                                }),
                              ],
                              1
                            ),
                            _c("h3", { staticClass: "mt-1" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.homeworksByLesson[_vm.screenLevel2Key].key
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._l(
                          _vm.homeworksByLesson[_vm.screenLevel2Key].values,
                          function (item, i) {
                            return _c(
                              "div",
                              {
                                key: item.key,
                                staticClass: "row mt-4 m-sm-5",
                                class:
                                  _vm.homeworksByLesson[_vm.screenLevel2Key]
                                    .values.length > 1
                                    ? "border border-primary rounded"
                                    : "",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "text-left col-10" },
                                  [
                                    _c(
                                      "h6",
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "book",
                                            "aria-hidden": "true",
                                          },
                                        }),
                                        _vm._v(" " + _vm._s(item.key) + " "),
                                      ],
                                      1
                                    ),
                                    item.key != "Doküman"
                                      ? _c(
                                          "b-badge",
                                          {
                                            style:
                                              "color: #fff;background-color:" +
                                              _vm.getColorCode(
                                                item.answeredTestCount,
                                                item.unAnsweredTestCount
                                              ),
                                            attrs: {
                                              pill: "",
                                              light: "",
                                              id:
                                                "tooltip-target-test-source-" +
                                                i,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.answeredTestCount +
                                                  item.unAnsweredTestCount
                                              ) + " Test "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target:
                                            "tooltip-target-test-source-" + i,
                                          triggers: "hover",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " İşaretleme yapılmış test sayısı: " +
                                            _vm._s(item.answeredTestCount) +
                                            " "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " İşaretleme yapılmamış test sayısı: " +
                                            _vm._s(item.unAnsweredTestCount) +
                                            " "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " Oran: %" +
                                            _vm._s(
                                              Math.round(
                                                (item.answeredTestCount /
                                                  (item.answeredTestCount +
                                                    item.unAnsweredTestCount)) *
                                                  100
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    item.key != "Doküman"
                                      ? _c(
                                          "b-badge",
                                          {
                                            staticClass: "mx-1",
                                            style:
                                              "color: #fff;background-color:" +
                                              _vm.getColorCode(
                                                item.answeredQuestionCount,
                                                item.unAnsweredQuestionCount
                                              ),
                                            attrs: {
                                              pill: "",
                                              id:
                                                "tooltip-target-question-source-" +
                                                i,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.unAnsweredQuestionCount +
                                                  item.answeredQuestionCount
                                              ) + " Soru "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.key != "Doküman"
                                      ? _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target:
                                                "tooltip-target-question-source-" +
                                                i,
                                              triggers: "hover",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " İşaretlenmiş soru sayısı: " +
                                                _vm._s(
                                                  item.answeredQuestionCount
                                                ) +
                                                " "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " İşaretlenmemiş soru sayısı: " +
                                                _vm._s(
                                                  item.unAnsweredQuestionCount
                                                ) +
                                                " "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " Oran: %" +
                                                _vm._s(
                                                  Math.round(
                                                    (item.answeredQuestionCount /
                                                      (item.answeredQuestionCount +
                                                        item.unAnsweredQuestionCount)) *
                                                      100
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-2 text-right" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName: "v-b-toggle",
                                            value:
                                              "innercollapse-" +
                                              _vm.screenLevel2Key +
                                              "-" +
                                              i,
                                            expression:
                                              "'innercollapse-' + screenLevel2Key + '-' + i",
                                          },
                                        ],
                                        attrs: {
                                          size: "sm",
                                          variant: "outline-primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.innercollapseOpenedKey ==
                                            _vm.screenLevel2Key + "-" + i
                                              ? (_vm.innercollapseOpenedKey =
                                                  null)
                                              : (_vm.innercollapseOpenedKey =
                                                  _vm.screenLevel2Key + "-" + i)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "ki icon-nm m-0 p-0",
                                          class:
                                            _vm.innercollapseOpenedKey ==
                                            _vm.screenLevel2Key + "-" + i
                                              ? "ki-arrow-up"
                                              : "ki-arrow-down",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-12 m-0 p-0" },
                                  [
                                    _c(
                                      "b-collapse",
                                      {
                                        staticClass: "row m-1",
                                        attrs: {
                                          visible: "",
                                          id:
                                            "innercollapse-" +
                                            _vm.screenLevel2Key +
                                            "-" +
                                            i,
                                        },
                                      },
                                      _vm._l(
                                        item.values,
                                        function (thing, idx) {
                                          return _c(
                                            "div",
                                            {
                                              key: idx,
                                              staticClass: "col-sm-6 col-md-4",
                                              class: "test-" + idx,
                                            },
                                            [
                                              thing.SourceTestId > 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "border rounded p-4",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "text-gray-800 text-hover-primary fw-bolder fs-6",
                                                          attrs: {
                                                            id: "atest-" + idx,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              !thing.SourceTestIsActivity
                                                                ? _vm.goToTestAnswer(
                                                                    thing.HomeworkId,
                                                                    thing.SourceTestId
                                                                  )
                                                                : ""
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("b-icon", {
                                                            attrs: {
                                                              icon: "pencil",
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                thing.SourceChapterName
                                                              ) +
                                                              " - " +
                                                              _vm._s(
                                                                thing.SourceTestName
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("br"),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-badge",
                                                        {
                                                          staticClass: "mt-1",
                                                          attrs: { pill: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Sayfa: " +
                                                              _vm._s(
                                                                thing.StartPage ==
                                                                  thing.EndPage
                                                                  ? thing.StartPage
                                                                  : thing.StartPage +
                                                                      "-" +
                                                                      thing.EndPage
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-badge",
                                                        {
                                                          staticClass:
                                                            "mt-1 mx-1",
                                                          style:
                                                            "color: #fff;background-color:" +
                                                            _vm.getColorCode(
                                                              thing.AnsweredQuestionCount,
                                                              thing.QuestionCount -
                                                                thing.AnsweredQuestionCount
                                                            ),
                                                          attrs: {
                                                            pill: "",
                                                            variant: "danger",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              thing.QuestionCount
                                                            ) + " Soru "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-badge",
                                                        {
                                                          staticClass:
                                                            "mt-1 mx-1",
                                                          attrs: { pill: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "momentDate"
                                                              )(thing.StartDate)
                                                            ) +
                                                              " - " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "momentDate"
                                                                )(thing.EndDate)
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      thing.SourceTestId > 0
                                                        ? _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "label label-inline",
                                                              attrs: {
                                                                id:
                                                                  "tooltip-target-achievement2-" +
                                                                  thing.HomeworkId +
                                                                  thing.SourceTestId,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    thing
                                                                      .Achievements
                                                                      .length
                                                                  ) +
                                                                  " Kazanım "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "tooltip-target-achievement2-" +
                                                              thing.HomeworkId +
                                                              thing.SourceTestId,
                                                            triggers: "focus",
                                                            placement: "right",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "title",
                                                                fn: function () {
                                                                  return [
                                                                    _vm._v(
                                                                      "Kazanımlar"
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        _vm._l(
                                                          thing.Achievements,
                                                          function (i, index) {
                                                            return _c(
                                                              "div",
                                                              { key: index },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      i.ChildAchievementName
                                                                    )
                                                                ),
                                                                _c("br"),
                                                                _c(
                                                                  "v-divider",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            index !=
                                                                            thing
                                                                              .Achievements
                                                                              .length -
                                                                              1,
                                                                          expression:
                                                                            "index != thing.Achievements.length -1",
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      inset: "",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                      thing.SourceTestIsActivity
                                                        ? [
                                                            _c("br"),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-right text-white mt-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    attrs: {
                                                                      variant:
                                                                        thing.IsHomeworkStudentAnswerDone
                                                                          ? "success"
                                                                          : "outline-danger",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.iActuallyDidThisHomework(
                                                                            thing
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm.loadingAnswer
                                                                      ? [
                                                                          _c(
                                                                            "b-spinner",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    "Lütfen bekleyiniz",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      : [
                                                                          _c(
                                                                            "b-form-checkbox",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  switch:
                                                                                    "",
                                                                                  size: "lg",
                                                                                  variant:
                                                                                    thing.IsHomeworkStudentAnswerDone
                                                                                      ? "success"
                                                                                      : "danger",
                                                                                },
                                                                              on: {
                                                                                input:
                                                                                  _vm.delayedSave,
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    thing.IsHomeworkStudentAnswerDone,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        thing,
                                                                                        "IsHomeworkStudentAnswerDone",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "thing.IsHomeworkStudentAnswerDone",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    thing.IsHomeworkStudentAnswerDone
                                                                                      ? "Yaptım"
                                                                                      : "Yapmadım"
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      !thing.HasAnswer
                                                        ? _c(
                                                            "b-progress",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                              attrs: {
                                                                max: thing.QuestionCount,
                                                                variant:
                                                                  "primary",
                                                                "show-progress":
                                                                  "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-progress-bar",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      thing.AnsweredQuestionCount,
                                                                    label: `${thing.progressValue.toFixed(
                                                                      0
                                                                    )}%`,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : !thing.SourceTestIsActivity &&
                                                          thing.SourceTestResult &&
                                                          thing.SourceTestResult
                                                            .CorrectCount ==
                                                            0 &&
                                                          thing.SourceTestResult
                                                            .WrongCount == 0
                                                        ? _c(
                                                            "b-progress",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                              attrs: {
                                                                max: thing.QuestionCount,
                                                                variant:
                                                                  "primary",
                                                                "show-progress":
                                                                  "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-progress-bar",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      thing.AnsweredQuestionCount,
                                                                    label: `${thing.progressValue.toFixed(
                                                                      0
                                                                    )}%`,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : !thing.SourceTestIsActivity &&
                                                          thing.SourceTestResult
                                                        ? _c(
                                                            "b-progress",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "b-popover",
                                                                  rawName:
                                                                    "v-b-popover.hover",
                                                                  value:
                                                                    thing
                                                                      .SourceTestResult
                                                                      .CorrectCount +
                                                                    " D / " +
                                                                    thing
                                                                      .SourceTestResult
                                                                      .WrongCount +
                                                                    " Y / " +
                                                                    thing
                                                                      .SourceTestResult
                                                                      .EmptyCount +
                                                                    " B",
                                                                  expression:
                                                                    "\n                    thing.SourceTestResult.CorrectCount +\n                    ' D / ' +\n                    thing.SourceTestResult.WrongCount +\n                    ' Y / ' +\n                    thing.SourceTestResult.EmptyCount +\n                    ' B'\n                  ",
                                                                  modifiers: {
                                                                    hover: true,
                                                                  },
                                                                },
                                                              ],
                                                              staticClass:
                                                                "mt-3",
                                                              attrs: {
                                                                max:
                                                                  thing
                                                                    .SourceTestResult
                                                                    .CorrectCount +
                                                                  thing
                                                                    .SourceTestResult
                                                                    .WrongCount +
                                                                  thing
                                                                    .SourceTestResult
                                                                    .EmptyCount,
                                                                "show-value":
                                                                  "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-progress-bar",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      thing
                                                                        .SourceTestResult
                                                                        .CorrectCount,
                                                                    variant:
                                                                      "success",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-progress-bar",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      thing
                                                                        .SourceTestResult
                                                                        .WrongCount,
                                                                    variant:
                                                                      "danger",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-progress-bar",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      thing
                                                                        .SourceTestResult
                                                                        .EmptyCount,
                                                                    variant:
                                                                      "secondary",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "border rounded p-4",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "text-gray-800 text-hover-primary fw-bolder fs-6",
                                                          attrs: {
                                                            id:
                                                              "adocument-" +
                                                              idx,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.goToDocumentAnswer(
                                                                thing.HomeworkId
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("b-icon", {
                                                            attrs: {
                                                              icon: "file-earmark-check",
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                thing.HomeworkName
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("br"),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.screenLevel == 3
                  ? _c("div", [
                      _vm.screenLevel3SourceTestId > 0
                        ? _c(
                            "div",
                            [
                              _c("HomeworkAnswersWidget", {
                                attrs: {
                                  sourceTestId: _vm.screenLevel3SourceTestId,
                                  homeworkId: _vm.screenLevel3HomeworkId,
                                  inputData:
                                    _vm.editedItem.AnsweredQuestionCount,
                                  isLocked: _vm.editedItem.IsLocked,
                                  endDate: _vm.editedItem.EndDate,
                                },
                                on: {
                                  "update:inputData": function ($event) {
                                    return _vm.$set(
                                      _vm.editedItem,
                                      "AnsweredQuestionCount",
                                      $event
                                    )
                                  },
                                  "update:input-data": function ($event) {
                                    return _vm.$set(
                                      _vm.editedItem,
                                      "AnsweredQuestionCount",
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.screenLevel,
                                  callback: function ($$v) {
                                    _vm.screenLevel = $$v
                                  },
                                  expression: "screenLevel",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.homeworksByLesson.length == 0 && _vm.isCurrent
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-chip",
                          {
                            staticStyle: { "font-size": "12px" },
                            attrs: {
                              large: "",
                              label: "",
                              color: "primary",
                              outlined: "",
                              to: { name: "odev-planlama" },
                            },
                          },
                          [
                            _vm._v(" Bugüne planladığın ödevin bulunmuyor."),
                            _c("br"),
                            _vm._v(" Planlama yapmak için tıkla. "),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm.homeworksByLesson.length == 0
                  ? _c("div", { staticClass: "text-center" }, [
                      _vm._v(
                        " " + _vm._s(_vm._f("moment")(_vm.dates[0])) + " - "
                      ),
                      _vm.dates[1]
                        ? _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm._f("moment")(_vm.dates[1]))
                            ),
                          ])
                        : _c("span", [_vm._v("∞")]),
                      _vm._v(" Tarihlerinde ödeviniz bulunmamaktadır. "),
                      _c("p", [
                        _vm._v(
                          "Üstte bulunan takvim ikonuna tıklayarak tarih filtresini kullanabilirsiniz."
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }