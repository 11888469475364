import Axios from 'axios'

const UploadService = {
  async uploadFiles(presignedUrls, files) {
    for (let index = 0; index < presignedUrls.length; index++) {
      const element = presignedUrls[index]
      await Axios.put(element, files[index], {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': files[index].type
        },
        transformRequest: [
          function (data, headers) {
            delete headers.common.Authorization
            delete headers.common.Tenant
            return data
          }
        ]
      })
      // .catch((error) => console.log(error))
    }
  },

  async uploadFile(presignedUrl, file) {
    await Axios.put(presignedUrl, file, {
      headers: {
        'x-amz-acl': 'public-read',
        'Content-Type': file.type
      },
      transformRequest: [
        function (data, headers) {
          delete headers.common.Authorization
          delete headers.common.Tenant
          return data
        }
      ]
    })
    // .catch((error) => console.log(error))
  }
}

export default UploadService
