<template>
  <div class="mt-10" align="center">
    <b-card no-body :style="isMobile ? 'max-width: 360px' : 'max-width: 960px'" class="p-2">
      <div class="row">
        <div class="col-12">
          <b-button
            size="sm"
            variant="outline-primary"
            @click="
              $emit('update:inputData', inputData)
              $emit('input', 2)
            "
          >
            <b-icon icon="arrow90deg-left"></b-icon> Ödev Listesine Dön
          </b-button>
        </div>
        <div class="col-12 text-center">
          <h6>Son Kontrol Tarihi: {{ endDate | momentDate }} Saat: {{ endDate | moment }}</h6>
        </div>
        <div class="col-12 text-center">
          <h4 style="font-weight: bold">{{ homework.HomeworkName }}</h4>
        </div>
        <div v-if="homework.HomeworkDescription" class="col-12 text-center">
          <span v-html="homework.HomeworkDescription"></span>
        </div>
      </div>
      <div
        v-if="homework.HomeworkAttachments.filter((s) => s.FileKey != null).length > 0"
        class="row my-2 mx-2"
      >
        <div class="col">
          <h4 style="color: blue">Ödev Dokümanları</h4>
          <FileAgent
            style="max-width: 480px"
            :maxSize="'100MB'"
            :files="homework.HomeworkAttachments"
          ></FileAgent>
        </div>
      </div>
      <div
        v-if="homework.HomeworkAttachments.filter((s) => s.Link != null).length > 0"
        class="row my-2 mx-2"
      >
        <div class="col">
          <h4 class="mb-2" style="color: red">YouTube Linkleri</h4>
          <div class="row">
            <div
              class="col d-flex justify-content-center flex-wrap"
              v-for="(item, idx) in links"
              :key="idx"
            >
              <iframe
                class="mx-1"
                width="auto"
                height="155"
                :src="item"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5" v-if="!isLocked">
        <div class="row">
          <div class="col">
            <v-textarea
                v-model="textAnswer.Comment"
                class="mx-1"
                label="Cevap Yaz"
                hint="Metin ekleyerek de ödevi cevaplayabilirsin."
                outlined
                auto-grow
                rows="4"
                style="max-width: 480px"
            >
            </v-textarea>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <v-file-input
              v-model="files"
              counter
              label="Cevap Yükle"
              multiple
              placeholder="Cevap Yükle"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              outlined
              :show-size="1000"
              style="max-width: 480px"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 3" color="primary" dark label small>
                  {{ text }}
                </v-chip>
                <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                  +{{ files.length - 2 }} Dosya
                </span>
              </template>
            </v-file-input>
          </div>
        </div>
        <div class="text-center m-3">
          <b-button
            variant="primary"
            :disabled="loading || isLocked || (files.length <= 0 && textAnswer.Comment == null)"
            @click="prepareUpload"
            >CEVAPLARI YÜKLE</b-button
          >
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col">
            <b-alert show variant="warning" style="max-width: 450px"
              >Ödevin teslim süresi dolmuştur, yükleme yapılamaz.</b-alert
            >
          </div>
        </div>
      </div>
      <div class="row" v-if="oldAnswers.length > 0">
        <div class="col">
          <v-divider class="mx-4"></v-divider>
          <h4 style="color: green" class="mt-3">Yüklediğim Cevaplar</h4>
          <FileAgent style="max-width: 480px" :files="oldAnswers"></FileAgent>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'
import FileAgent from '@/view/components/FileAgent'
import Swal from 'sweetalert2'
import CompressionService from '@/core/services/image-compression.service.js'
import UploadService from '@/core/services/core-upload.service.js'

export default {
  props: ['homeworkId', 'screenLevel', 'isStrict', 'endDate', 'homework'],
  name: 'odev-cevaplari',
  components: { FileAgent },
  data() {
    return {
      uploadAnswerStatus: false,
      files: [],
      oldAnswers: [],
      textAnswer: {},
      loading: false,
      signedUrls: []
    }
  },
  computed: {
    links() {
      if (this.homework.HomeworkAttachments) {
        return this.homework.HomeworkAttachments.filter((s) => s.Link != null).map((s) =>
          s.Link.replace('watch?v=', 'embed/')
        )
      } else {
        return []
      }
    },
    isLocked() {
      if (this.isStrict) {
        return moment().isSameOrAfter(moment(this.endDate))
      } else {
        return false
      }
    },
    isMobile() {
      return window.outerWidth < 540
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('LT') : ''
    },
    momentDate: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM') : ''
    }
  },
  mounted() {
    this.getOldAnswers()
  },
  methods: {
    async prepareUpload() {
      this.loading = true
      //compressorjs heic uzantısını desteklemiyor
      const fileImages = this.files.filter((s) => s.type.includes('image') && !s.type.includes('heic'))
      await CompressionService.compressMultipleImages(fileImages)
        .then((data) => {
          this.files = data.concat(this.files.filter((s) => !s.type.includes('image') || s.type.includes('heic')))
          this.uploadAnswers()
        })
        .catch((err) => {
          Swal.fire({
            toast: true,
            position: 'bottom',
            title: err,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000
          })
          this.loading = false
        })
    },

    uploadAnswers() {
      if (this.textAnswer.Comment != null) {
        this.textAnswer.HomeworkId = this.homeworkId
        ApiService.setHeader()
        ApiService.put('api/ManualHomeworkStudentAnswer', this.textAnswer)
          .then(() => {
            this.loading = false
          })
          .catch(({ response }) => {
            ApiService.showError(response)
          })
      }

      if (this.files.length > 0) {
        const formData = new FormData()
        for (const file of this.files) {
          formData.append('files', file, file.name)
        }
        ApiService.setHeader()
        ApiService.postwithconfig(
          `api/ManualHomeworkStudentAnswer/upload-file/` + this.homeworkId,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
          .then((data) => {
            UploadService.uploadFiles(data.data, this.files)
            Swal.fire({
              toast: true,
              position: 'bottom',
              title: 'Başarılı',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            })
            this.files = []
            this.loading = false
            this.getOldAnswers()
          })
          .catch(({ response }) => {
            ApiService.showError(response)
            this.loading = false
          })
      }
    },

    getOldAnswers() {
      ApiService.setHeader()
      ApiService.post('api/ManualHomeworkStudentAnswer', {
        HomeworkId: this.homeworkId
      })
        .then((data) => {
          this.oldAnswers = data.data.filter((s) => s.FileKey != null)
          this.textAnswer =
            data.data.filter((s) => s.Comment != null).length > 0
              ? data.data.filter((s) => s.Comment != null)[0]
              : {}
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>
