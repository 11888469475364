var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "", id: "first" } },
    [
      _c(
        "v-card-title",
        { staticClass: "px-1 mx-md-3 pb-0" },
        [
          _c(
            "b-btn",
            {
              staticClass: "mx-0 px-1 p-sm-2 odevlerim",
              attrs: {
                size: "sm",
                variant:
                  !_vm.isCompleted && !_vm.isNotCompleted && !_vm.isIncomplete
                    ? "primary"
                    : "",
              },
              on: {
                click: function ($event) {
                  _vm.isCompleted = false
                  _vm.isNotCompleted = false
                  _vm.isIncomplete = false
                  _vm.getHomeworksFromApi()
                  _vm.screenLevel = 1
                },
              },
            },
            [_vm._v("Ödevlerim ")]
          ),
          _c(
            "b-btn",
            {
              staticClass: "mx-1 mx-sm-2 px-1 p-sm-2 yaptiklarim",
              attrs: {
                size: "sm",
                variant:
                  _vm.isCompleted && !_vm.isNotCompleted ? "primary" : "",
              },
              on: {
                click: function ($event) {
                  _vm.isCompleted = true
                  _vm.isNotCompleted = false
                  _vm.isIncomplete = false
                  _vm.getHomeworksFromApi()
                  _vm.screenLevel = 1
                },
              },
            },
            [_vm._v("Yaptıklarım ")]
          ),
          _c(
            "b-btn",
            {
              staticClass: "mx-0 px-1 p-sm-2 yapmadiklarim",
              attrs: {
                size: "sm",
                variant:
                  !_vm.isCompleted && _vm.isNotCompleted ? "primary" : "",
              },
              on: {
                click: function ($event) {
                  _vm.isCompleted = false
                  _vm.isNotCompleted = true
                  _vm.isIncomplete = false
                  _vm.getHomeworksFromApi()
                  _vm.screenLevel = 1
                },
              },
            },
            [_vm._v("Yapmadıklarım ")]
          ),
          _c(
            "b-btn",
            {
              staticClass: "mx-1 mx-sm-2 px-1 p-sm-2",
              attrs: { size: "sm", variant: _vm.isIncomplete ? "primary" : "" },
              on: {
                click: function ($event) {
                  _vm.isCompleted = false
                  _vm.isNotCompleted = false
                  _vm.isIncomplete = true
                  _vm.getHomeworksFromApi()
                  _vm.screenLevel = 1
                },
              },
            },
            [_vm._v("Eksik Yaptıklarım ")]
          ),
          _c(
            "v-dialog",
            {
              ref: "dialog",
              attrs: {
                "return-value":
                  /* eslint-disable vue/no-mutating-props */ _vm.dates,
                persistent: "",
                width: "290px",
              },
              on: {
                "update:returnValue": function ($event) {
                  /* eslint-disable vue/no-mutating-props */ _vm.dates = $event
                },
                "update:return-value": function ($event) {
                  /* eslint-disable vue/no-mutating-props */ _vm.dates = $event
                },
              },
              model: {
                value: _vm.dateDialog,
                callback: function ($$v) {
                  _vm.dateDialog = $$v
                },
                expression: "dateDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-date-picker",
                    {
                      attrs: {
                        scrollable: "",
                        range: "",
                        color: "green lighten-1",
                        "header-color": "primary",
                        locale: "tr",
                        min: "2021-09-13",
                        "first-day-of-week": "1",
                      },
                      model: {
                        value:
                          /* eslint-disable vue/no-mutating-props */ _vm.dates,
                        callback: function ($$v) {
                          /* eslint-disable vue/no-mutating-props */ _vm.dates =
                            $$v
                        },
                        expression:
                          "/* eslint-disable vue/no-mutating-props */ dates",
                      },
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dateDialog = false
                            },
                          },
                        },
                        [_vm._v(" İPTAL")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.getHomeworksFromApi()
                              _vm.$refs.dialog.save(_vm.dates)
                            },
                          },
                        },
                        [_vm._v(" ARA ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "mx-3",
                      model: {
                        value: _vm.orderByCreatedDate,
                        callback: function ($$v) {
                          _vm.orderByCreatedDate = $$v
                        },
                        expression: "orderByCreatedDate",
                      },
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: "Son Kontrol Tarihine Göre",
                          value: false,
                        },
                      }),
                      _c("v-radio", {
                        attrs: {
                          label: "Ödev Yapma Tarihine Göre",
                          value: true,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-progress-linear", {
        staticClass: "mb-0",
        attrs: {
          active: _vm.loading,
          indeterminate: _vm.loading,
          color: "primary",
        },
      }),
      _c("v-card-text", [
        _vm.$route.name == "odevlerim" &&
        (_vm.screenLevel == 1 || _vm.screenLevel == 2)
          ? _c("div", [
              _c(
                "div",
                { staticClass: "d-flex justify-content-around w-100 mb-2" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", icon: "" },
                      on: { click: _vm.previousWeek },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(" mdi-chevron-left"),
                      ]),
                      _vm._v(" ÖNCEKİ "),
                    ],
                    1
                  ),
                  _c("v-toolbar-title", [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-center align-center" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("momentDate")(_vm.dates[0])) +
                            " - " +
                            _vm._s(_vm._f("momentDate")(_vm.dates[1])) +
                            " "
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "float-right ml-1 takvim",
                            attrs: { icon: "", small: "" },
                            on: {
                              click: function ($event) {
                                _vm.dateDialog = true
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v(" mdi-calendar")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", icon: "" },
                      on: { click: _vm.nextWeek },
                    },
                    [
                      _vm._v(" SONRAKİ "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v(" mdi-chevron-right"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.screenLevel == 1
          ? _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "table-responsive d-none d-sm-block" },
                  [
                    _c(
                      "table",
                      {
                        staticClass:
                          "table table-hover table-vertical-center mt-2",
                      },
                      [
                        _c("thead", [
                          _c("tr", { staticClass: "text-center" }, [
                            _c(
                              "th",
                              {
                                staticClass: "text-start",
                                attrs: { scope: "col" },
                              },
                              [_vm._v("Ders")]
                            ),
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v("Ödev Sayısı"),
                            ]),
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v("Yapılan Ödev Sayısı"),
                            ]),
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v("Yapılmayan Ödev Sayısı"),
                            ]),
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v("Eksik Yapılan Ödev Sayısı"),
                            ]),
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v("Ödevin Ayrıntısı"),
                            ]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm.loading
                              ? _c(
                                  "tr",
                                  { staticClass: "text-center" },
                                  [
                                    _c("b-spinner", {
                                      attrs: {
                                        variant: "primary",
                                        label: "Spinning",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._l(
                                  _vm.homeworksByLesson,
                                  function (element, index) {
                                    return [
                                      _c(
                                        "tr",
                                        {
                                          key: index,
                                          staticClass: "text-center",
                                        },
                                        [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-start",
                                              staticStyle: {
                                                "max-width": "130px",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(element.key)),
                                                _c("br"),
                                              ]),
                                            ]
                                          ),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  element.unAnsweredManualHomeworkCount +
                                                    element.answeredManualHomeworkCount +
                                                    element.incompleteManualHomeworkCount
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  element.answeredManualHomeworkCount
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  element.unAnsweredManualHomeworkCount
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                element.incompleteManualHomeworkCount
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.left",
                                                      value: "Ödevin Ayrıntısı",
                                                      expression:
                                                        "'Ödevin Ayrıntısı'",
                                                      modifiers: {
                                                        hover: true,
                                                        left: true,
                                                      },
                                                    },
                                                  ],
                                                  attrs: {
                                                    size: "sm",
                                                    variant: "outline-primary",
                                                    id: "buttonLesson" + index,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.screenLevel = 2
                                                      _vm.screenLevel2Key =
                                                        index
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "ki ki-long-arrow-next icon-nm m-0 p-0",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  }
                                ),
                            _c("tr", { staticClass: "text-center" }, [
                              _c("td", { staticClass: "text-start" }, [
                                _vm._v("TOPLAM"),
                              ]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.homeworkByLessonSum
                                        .answeredManualHomeworkCount +
                                        _vm.homeworkByLessonSum
                                          .unAnsweredManualHomeworkCount +
                                        _vm.homeworkByLessonSum
                                          .incompleteManualHomeworkCount
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.homeworkByLessonSum
                                        .answeredManualHomeworkCount
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.homeworkByLessonSum
                                        .unAnsweredManualHomeworkCount
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.homeworkByLessonSum
                                      .incompleteManualHomeworkCount
                                  )
                                ),
                              ]),
                              _c("td"),
                            ]),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._l(_vm.homeworksByLesson, function (element, index) {
                  return _c(
                    "div",
                    { key: element.key, staticClass: "d-flex mt-0 d-sm-none" },
                    [
                      _c("div", { staticClass: "text-left solsutun" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-dark-75 font-weight-bolder font-size-lg",
                          },
                          [_vm._v(" " + _vm._s(element.key) + " ")]
                        ),
                        _c("p", { staticClass: "text-muted font-weight-bold" }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-left flex-grow-1 testsoru ml-2" },
                        [
                          _c(
                            "span",
                            { staticClass: "font-weight-bolder" },
                            [
                              element.unAnsweredManualHomeworkCount > 0 ||
                              element.answeredManualHomeworkCount > 0
                                ? _c(
                                    "b-badge",
                                    {
                                      class: "tooltip-target-test-" + index,
                                      style:
                                        "color: #fff;background-color:" +
                                        _vm.getColorCode(
                                          element.answeredManualHomeworkCount,
                                          element.unAnsweredManualHomeworkCount
                                        ),
                                      attrs: {
                                        pill: "",
                                        light: "",
                                        id: "tooltip-target-test-" + index,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          element.answeredManualHomeworkCount +
                                            element.unAnsweredManualHomeworkCount
                                        ) + " Ödev"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "text-right ayrinti mt-2" },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.left",
                                  value: "Ödevin Ayrıntısı",
                                  expression: "'Ödevin Ayrıntısı'",
                                  modifiers: { hover: true, left: true },
                                },
                              ],
                              attrs: {
                                size: "sm",
                                variant: "outline-primary",
                                id: "buttonLesson" + index,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.screenLevel = 2
                                  _vm.screenLevel2Key = index
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "ki ki-long-arrow-next icon-nm m-0 p-0",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                _vm.homeworksByLesson.length > 0
                  ? _c("div", { staticClass: "d-flex mt-0 d-sm-none" }, [
                      _c("div", { staticClass: "text-left" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-dark-75 font-weight-bolder mt-1 mr-1 font-size-lg",
                          },
                          [_vm._v("Toplam")]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-left flex-grow-1" },
                        [
                          _vm.homeworkByLessonSum.answeredManualHomeworkCount +
                            _vm.homeworkByLessonSum
                              .unAnsweredManualHomeworkCount >
                          0
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "m-1",
                                  style:
                                    "color: #fff;background-color:" +
                                    _vm.getColorCode(
                                      _vm.homeworkByLessonSum
                                        .answeredManualHomeworkCount,
                                      _vm.homeworkByLessonSum
                                        .unAnsweredManualHomeworkCount
                                    ),
                                  attrs: {
                                    pill: "",
                                    light: "",
                                    id: "tooltip-target-test-sum",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.homeworkByLessonSum
                                        .answeredManualHomeworkCount +
                                        _vm.homeworkByLessonSum
                                          .unAnsweredManualHomeworkCount
                                    ) + " Ödev "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "text-right" }),
                    ])
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm.screenLevel == 2
          ? _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "row my-2 mx-sm-2" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mx-3 mt-0 geri",
                        attrs: {
                          size: "sm",
                          id: "geri",
                          variant: "outline-primary",
                        },
                        on: {
                          click: function ($event) {
                            _vm.screenLevel = 1
                            _vm.screenLevel2Key = null
                          },
                        },
                      },
                      [_c("b-icon", { attrs: { icon: "arrow90deg-left" } })],
                      1
                    ),
                    _c("h3", { staticClass: "mt-1" }, [
                      _vm._v(
                        _vm._s(_vm.homeworksByLesson[_vm.screenLevel2Key].key)
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._l(
                  _vm.homeworksByLesson[_vm.screenLevel2Key].values,
                  function (item, i) {
                    return _c(
                      "div",
                      {
                        key: item.key,
                        staticClass: "row mt-4 m-sm-5",
                        class:
                          _vm.homeworksByLesson[_vm.screenLevel2Key].values
                            .length > 1
                            ? "border border-primary rounded"
                            : "",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-12 m-0 p-0" },
                          [
                            _c(
                              "b-collapse",
                              {
                                staticClass: "row m-1",
                                attrs: {
                                  visible: "",
                                  id:
                                    "innercollapse-" +
                                    _vm.screenLevel2Key +
                                    "-" +
                                    i,
                                },
                              },
                              _vm._l(item.values, function (thing, idx) {
                                return _c(
                                  "div",
                                  {
                                    key: idx,
                                    staticClass: "col-sm-6 col-md-4",
                                    class: "test-" + idx,
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "border rounded p-4" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-gray-800 text-hover-primary fw-bolder fs-6",
                                            attrs: { id: "adocument-" + idx },
                                            on: {
                                              click: function ($event) {
                                                return _vm.goToDocumentAnswer(
                                                  thing.HomeworkId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                icon: "file-earmark-check",
                                                "aria-hidden": "true",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(thing.HomeworkName) +
                                                " "
                                            ),
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "ml-1",
                                                attrs: { "x-small": "" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      thing.EndDate
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }
                ),
              ],
              2
            )
          : _vm._e(),
        _vm.screenLevel == 3
          ? _c(
              "div",
              [
                _c("ManualHomeworkAnswersWidget", {
                  attrs: {
                    homework: _vm.editedItem,
                    homeworkId: _vm.screenLevel3HomeworkId,
                    isStrict: _vm.editedItem.IsStrict,
                    endDate: _vm.editedItem.EndDate,
                  },
                  model: {
                    value: _vm.screenLevel,
                    callback: function ($$v) {
                      _vm.screenLevel = $$v
                    },
                    expression: "screenLevel",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.homeworksByLesson.length == 0
          ? _c("div", { staticClass: "text-center" }, [
              _vm._v(" " + _vm._s(_vm._f("moment")(_vm.dates[0])) + " - "),
              _vm.dates[1]
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm._f("moment")(_vm.dates[1]))),
                  ])
                : _c("span", [_vm._v("∞")]),
              _vm._v(" Tarihlerinde ödeviniz bulunmamaktadır. "),
              _c("p", [
                _vm._v(
                  "Üstte bulunan takvim ikonuna tıklayarak tarih filtresini kullanabilirsiniz."
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }